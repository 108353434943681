"use client"

import { LucideIcon } from "lucide-react"
import { cn } from "lib/utils"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip"
import { useLocation, useNavigate, useParams } from "react-router-dom"

interface NavProps {
  isCollapsed: boolean
  links: {
    title: string
    route?: string
    label?: string
    secondaryIcon?: string
    icon: LucideIcon
  }[]
}

export function Nav({ links, isCollapsed }: NavProps) {
  const navigate = useNavigate()
  const location = useLocation();

  const { workspaceId } = useParams();

  return (
    <div
      data-collapsed={isCollapsed}
      className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2"
    >
      <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
        {links.map((link, index) =>
          isCollapsed ? (
            <Tooltip key={index} delayDuration={0}>
              <TooltipTrigger asChild>
                <a
                  role="button"
                  onClick={() => {if (link.route) navigate('/' + workspaceId + link.route)}}
                  className={cn(
                    // buttonVariants({ variant: link.variant, size: "sm" }),
                    link.route !== location.pathname &&
                      "hover:bg-slate-100",
                    link.route === location.pathname &&
                      "bg-slate-100 hover:bg-slate-200 dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white",
                    "h-9 w-9 flex items-center justify-center active",
                  )}
                >
                  <link.icon className="h-5 w-5" />
                  <span className="sr-only">{link.title}</span>
                </a>
              </TooltipTrigger>
              <TooltipContent side="right" className="flex items-center gap-4 justify-start align-start">
                <div className="mr-auto">{link.title}</div>
                {link.label && (
                  <span className="text-muted-foreground mr-2">
                    {link.label}
                    
                  </span>
                )}
                {link.secondaryIcon && (
                  <link.secondaryIcon />
                )}
              </TooltipContent>
            </Tooltip>
          ) : (
            <a
              key={index}
              role="button"
              onClick={() => {if (link.route) navigate('/' + workspaceId + link.route)}}
              className={cn(
                // buttonVariants({ variant: link.variant, size: "sm" }),
                link.route !== location.pathname &&
                  "hover:bg-slate-100",
                  link.route === location.pathname &&
                  "bg-slate-100 hover:bg-slate-200 dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white",
                "px-4 active"
              )}
            >
              <link.icon className="mr-2 h-5 w-5" />
              <div className="mr-auto">{link.title}</div>
              {link.label && (
                <div
                  className={cn(
                    "text-slate-500",
                    link.secondaryIcon && "mr-2",
                    link.variant === "default" &&
                      "dark:text-white"
                  )}
                >
                  {link.label}
                </div>
              )}
              {link.secondaryIcon && (
                <link.secondaryIcon className="text-slate-500 h-5 w-5" />
              )}
            </a>
          )
        )}
      </nav>
    </div>
  )
}