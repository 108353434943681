import { Outlet, useNavigate } from 'react-router-dom'
import React, { useEffect } from "react"
import { useAuth } from "hooks/AuthContext"
import { Toaster } from 'components/ui/sonner';
import {
    TooltipProvider,
  } from "components/ui/tooltip"

export const PrivateRoutes = () => {
    const { user, loading } = useAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        if(!user && !loading) {
            navigate('/signin');
        } 
    }, [user, loading]);

    if(!user && loading) return <div>Loading...</div>;

    return (
    <TooltipProvider delayDuration={0}>
      <Outlet />
      <Toaster position="bottom-center" className='bottom-4' />
    </TooltipProvider>
    )
};