"use client"

import { useState, useRef} from "react"
import * as React from "react"
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
  } from "components/ui/resizable.tsx"
import {
    LogOut,
    LayoutDashboard,
  } from "lucide-react"
  
import { Nav } from "components/navs/nav.tsx"
import { cn } from "lib/utils.tsx"
import { Separator } from "components/ui/separator.tsx"

import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "components/ui/tooltip.tsx"
import { Outlet } from "react-router-dom"
import { WorkspaceSwitcher } from "components/navs/workspace-switcher.tsx"
import Logo from "../logo/logo"
import { signOut } from "services/Firebase"
import { useAuth } from "hooks/AuthContext"
import { WorkspaceProvider, useWorkspace } from "hooks/WorkspaceContext"
import { Button } from "components/ui/button.tsx"
import Feedback from "../feedback"
import { useEffect } from "react"
import { useWindowSize } from "hooks/useWindowSize"

const routes =
[
  // {
  //   title: "Insights",
  //   // label: "128",
  //   route: "/",
  //   icon: Zap,
  // },
  // {
  //   title: "Responses",
  //   route: "/responses",
  //   // label: "9",
  //   icon: Table,
  // },
  {
    title: "Whiteboards",
    route: "/whiteboards",
    // label: "9",
    icon: LayoutDashboard,
  }
]

export default () => {
    const [defaultLayout, setDefaultLayout] = useState([20, 80])
    const navCollapsedSize = 3;
    const [isCollapsed, setIsCollapsed] = useState(false);
    const sidebarRef = useRef(null)
    const { width } = useWindowSize();
    const mediumBreakpoint = 768;

    useEffect(() => {
      // Define your breakpoint for medium size
      if(!sidebarRef.current) return
      // Check if the window width is less than or equal to the medium breakpoint
      if (width <= mediumBreakpoint) {
        sidebarRef.current.collapse();
      } else {
        // console.log(sidebarRef.current)
        sidebarRef.current.expand();
      }

      // Always collapse
      sidebarRef.current.collapse();

      setIsCollapsed(sidebarRef.current.isCollapsed);
    }, [width]);

    return(
            <ResizablePanelGroup
              direction="horizontal"
              onLayout={(sizes) => {
                document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                  sizes
                )}`
              }}
              className="h-svh items-stretch"
            >
              <ResizablePanel
                ref={sidebarRef}
                defaultSize={defaultLayout[0]}
                collapsedSize={navCollapsedSize}
                collapsible={true}
                minSize={7}
                maxSize={15}
                onCollapse={() => {
                  setIsCollapsed(true)
                  document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                    true
                  )}`
                }}

                onExpand={() => {
                  setIsCollapsed(false)
                  document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                    false
                  )}`
                }}
                className={cn(isCollapsed && "min-w-[50px] transition-all duration-300 ease-in-out",
                width <= mediumBreakpoint && isCollapsed && "border border-r border-slate-200",
                "bg-white dark:bg-slate-800")}
              >
                <SidebarContent isCollapsed={isCollapsed} /> 
              </ResizablePanel>
              {width >= mediumBreakpoint && <ResizableHandle withHandle />}
            <ResizablePanel defaultSize={defaultLayout[1]}>
                <Outlet />
            </ResizablePanel>
          </ResizablePanelGroup>
    )
}

const SidebarContent = ({isCollapsed}) => {
  return(
    <div className="flex flex-col h-full">
      <div className={cn("space-y-3 p-2 justify-center", isCollapsed ? 'items-start h-[52px]': 'items-center')}>
        <Logo showName={!isCollapsed} />
      </div>
      <Separator />
        {/* TODO: Implement, not MVP */}
        <div className={cn("flex h-[52px] w-full items-center justify-center", isCollapsed ? 'h-[52px]': 'px-2')}>
          <WorkspaceSwitcher isCollapsed={isCollapsed} />
        </div>
        <div className="grow flex flex-col justify-between">
          <Nav
            isCollapsed={isCollapsed}
            links={routes}
          />
          {/* <Separator />
          <Nav
            isCollapsed={isCollapsed}
            links={[
              {
                title: "Social",
                route: "/social",
                // label: "972",
                icon: Users2,
                variant: "ghost",
              },
            ]}
          /> */}

          <div className="flex flex-col gap-2 px-2 py-3">
            <LogOutNav isCollapsed={isCollapsed} onClick={() => signOut()}/>
            <Feedback isCollapsed={isCollapsed} />
          </div>

      </div>
    </div>
  )
}

const LogOutNav = ({isCollapsed, onClick}) => {
  const { user } = useAuth()
  return(
  <div className="">
    {isCollapsed ? 
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <div
            role="button"
            onClick={(e) => onClick(e)}
            className={cn(
              "hover:bg-slate-100 h-9 w-9 flex items-center justify-center active",
            )}
          >
            <LogOut className="h-5 w-5" />
            <span className="sr-only subtle font-medium transition-colors text-gray-700 hover:text-gray-900 pointer-events-none truncate">{user?.email}</span>
          </div>
        </TooltipTrigger>
        <TooltipContent side="right" className="flex items-center gap-4">
          Sign out
        </TooltipContent>
      </Tooltip>
      :
      <div className="flex flex-row align-center justify-between">
        <div className="my-auto subtle font-medium transition-colors text-gray-700 hover:text-gray-900 pointer-events-none truncate">{user?.email}</div>
        <div 
          role="button"
          onClick={(e) => onClick(e)}
          className="transition-colors hover:bg-slate-100 p-2 rounded-md"> 
            <LogOut className="h-5 w-5"/>
        </div>
      </div>
    }
  </div>
)}