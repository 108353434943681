import { Separator } from "components/ui/separator";
import { LayoutDashboard, PlusIcon, Send } from "lucide-react";

import { Button } from "components/ui/button";
import useLayouts from "hooks/useLayouts";
import { timeAgo } from "hooks/useDate"
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover"

import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuShortcut,
    ContextMenuTrigger,
  } from "components/ui/context-menu"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "components/ui/dialog"
import * as DialogPrimitive from "@radix-ui/react-dialog";

import { useEffect, useState } from "react";
import NoWhiteboards from "components/empty-states/no-whiteboards";
import { useWorkspace } from "hooks/WorkspaceContext";

const nav = [
    { name: "All", href: "/all" },
    { name: "Recent", href: "/recents" },
    { name: "Created by me", href: "/created-by-me" },
]

export default () => {
    const { layouts, loading } = useLayouts()
    const { workspace } = useWorkspace();
    const { workspaceId } = useParams();
    const navigate = useNavigate();

    return(
    <div className="flex sm:h-svh h-full flex flex-col px-3 py-3 md:px-5 md:py-7 lg:px-9 lg:py-12 overflow-auto">
        <div className="flex flex-row justify-between items-center h-[40px]">
            <div className="flex flex-row items-center space-x-2">
                {nav.map((item) => (
                <NavLink key={item.name} to={'/' + item.href} className="text-nowrap text-slate-900 dark:text-slate-200 my-auto text-sm font-medium px-2 py-1 rounded-md border border-slate-200 bg-slate-50 hover:bg-slate-200 dark:hover:bg-slate-700 transition-colors duration-200">
                    {item.name}
                </NavLink>
                ))}
            </div>
            <div className="flex flex-row items-center space-x-3">
                <div className="flex flex-row items-center space-x-3">

                    {/* If more than one role, show the role */}
                    {workspace && Object.keys(workspace.roles).length > 1 && (
                        <p className="text-sm text-slate-500">
                            {Object.keys(workspace.roles).length - 1} others
                        </p>
                    )}
                    <Button onClick={() => navigate(`/${workspaceId}/settings/sharing`)} size="sm" >
                        <Send className="h-4 w-4 mr-1" />
                        Invite
                    </Button>
                </div>

                <CreateNewWhiteboard size="sm" />
            </div>
        </div>
        <div className="grid md:grid-cols-3 xl:grid-cols-4 sm:gride-cols-2 gap-3">
            {layouts.slice() // Create a copy of the array to avoid mutating the original
                .sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds) // Sort the layouts by createdAt.seconds
                .map((layout, index) => (
                // If layout.type is whiteboard
                <Whiteboard layout={layout} key={index} />
            ))}
        </div>
        {layouts?.length === 0 && !loading && <NoWhiteboards />}
    </div>
    )
}

const LayoutContextMenu = (props) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const down = (e : KeyboardEvent) => {
        if (e.key === "r" && (e.metaKey || e.ctrlKey)) {
            e.preventDefault()
            setOpen((open) => !open)
        }
        }
        document.addEventListener("keydown", down)
        return () => document.removeEventListener("keydown", down)
    }, [])

    const onRename = (e : FormEvent) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const name = formData.get("name")
        props.onRename(name)
    }


    return(
    <ContextMenu>
        <ContextMenuTrigger asChild>
            {props.children}
        </ContextMenuTrigger>
        <ContextMenuContent className="flex flex-col">
            <Dialog>
                <DialogTrigger>
                    <ContextMenuItem onSelect={(e) => e.preventDefault()}>
                        Rename
                    </ContextMenuItem>
                </DialogTrigger>
                <DialogContent>
                    <form onSubmit={onRename} >
                    <DialogHeader>
                        <DialogTitle>Rename whiteboard</DialogTitle>
                        <DialogDescription>
                        You can rename your whiteboard here. This will change the name for anyone in the workspace.
                        <div className="grid flex-1 gap-2 py-4">
                            <Label htmlFor="link" className="sr-only">
                            Name
                            </Label>
                            <Input
                            id="link"
                            defaultValue={props.layout?.name || ""}
                            name="name"
                            type="text"
                            placeholder="Enter a name"
                            className="h-9"
                            />
                        </div>
                    </DialogDescription>
                    </DialogHeader>
                    
                    <DialogFooter className="flex flex-row justify-end w-full">
                        <DialogPrimitive.Close >
                            <Button 
                            size={"sm"}
                            role="submit"
                            onSubmit={(e) => onSubmit(e)}>Rename</Button>
                        </DialogPrimitive.Close>
                    </DialogFooter>
                    </form>

                </DialogContent>
            </Dialog>
            <ContextMenuItem onSelect={(e) => props.onDuplicate(e)}>
                Duplicate
            </ContextMenuItem>
            <Dialog>
                <DialogTrigger>
                    <ContextMenuItem onSelect={(e) => e.preventDefault()}>
                    Delete
                    </ContextMenuItem>
                </DialogTrigger>
                <DialogContent>
                <DialogHeader>
                    <DialogTitle>Are you sure absolutely sure?</DialogTitle>
                    <DialogDescription>
                        This action cannot be undone. This will permanently delete
                        your whiteboard and remove your data from our servers.
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter className="flex flex-row justify-between w-full">
                    <DialogPrimitive.Close >
                        <Button 
                        variant={"link"} 
                        size={"sm"}
                        onClick={() => {}}>Cancel</Button>
                    </DialogPrimitive.Close>
                    <DialogPrimitive.Close >
                        <Button onClick={() => props.onDelete()} 
                        className="ml-auto bg-red-500 hover:bg-red-600" 
                        size={"sm"}
                        >Delete</Button>
                    </DialogPrimitive.Close>
                </DialogFooter>
                </DialogContent>
            </Dialog>
        </ContextMenuContent>
    </ContextMenu>
    )
}

const Whiteboard = ({layout}) => {
    const { deleteLayout, updateLayout, duplicateLayout } =  useLayouts()
    const navigate = useNavigate();
    const { workspaceId } = useParams();

    if(layout.type !== "whiteboard") return(null)
    return( 
        <LayoutContextMenu 
        onDelete={() => deleteLayout(layout.id)} 
        onDuplicate={() => duplicateLayout(layout.id)} 
        onRename={(name) => {updateLayout(layout.id, {name: name})}} 
        layout={layout}>
            <div role="button"
                onClick={() => {
                    navigate('/' + workspaceId + '/' + layout.type + '/' + layout.id)
                }}
                className="size-4 bg-red-500">
                
            </div>
        </LayoutContextMenu>
    )
}

export function CreateNewWhiteboard(props, {onCreate = () => {}}) {
    const {addLayout} = useLayouts();

    const createNew = (e) => {
        e.preventDefault();
        addLayout({name: e.target.name.value});
        onCreate();
    }

    return (
    <Popover>
        <PopoverTrigger>
            <Button size="sm" {...props}>New File</Button>
        </PopoverTrigger>
        <PopoverContent align="end" className="w-[320px]">
            <div className="flex flex-col space-y-2 text-center sm:text-left">
            <h3 className="text-lg font-semibold">Create new whiteboard</h3>
            <p className="text-sm text-muted-foreground">
                Anyone in your workspace will be able to view and edit this whiteboard.
            </p>
            </div>
            <form onSubmit={createNew} className="flex items-center space-x-2 pt-4">
            <div className="grid flex-1 gap-2">
                <Label htmlFor="link" className="sr-only">
                Link
                </Label>
                <Input
                id="link"
                defaultValue="New whiteboard"
                name="name"
                type="text"
                placeholder="Enter a name"
                className="h-9"
                />
            </div>
            <Button type="submit" size="sm" className="px-3">
                <span className="sr-only">Create</span>
                <PlusIcon className="h-4 w-4" />
            </Button>
            </form>
        </PopoverContent>
    </Popover>
    )
}