import { Button } from "components/ui/button";
import { Minus, Plus } from "lucide-react";
import { useControls } from "components/whiteboard/src/hooks/ControlContext";
import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import { Camera } from "components/whiteboard/src/types";

const Zoom = () => {
    const camera: Camera = useSelf((me) => me.presence.camera);

    const centerCamera = useMutation(
        ({ self, setMyPresence }) => {
          // Pan the camera based on the wheel delta
          setMyPresence({ camera : {
              x: 0,
              y: 0,
              zoom: 1,
            }
          });
        },[]);

    const onZoomChange = useMutation(
        ({ self, setMyPresence }, delta: number) => {
            // Limit zoom
            const newZoom = Math.min(Math.max(self.presence.camera.zoom + delta, 0.1), 1.25);
            // Pan the camera based on the wheel delta
            setMyPresence({ camera : {
                x: self.presence.camera.x,
                y: self.presence.camera.y,
                zoom: newZoom,
            }
            });
        },[]);

    return (
        <div
            className="absolute inline-flex bottom-4 right-4 z-10 rounded-lg bg-white flex flex-row justify-between items-center shadow-md border border-slate-200"
        >
            <Button size={"sm"} variant={"ghost"} className="py-1 px-3 flex flex-row items-center space-x-2" onClick={(e) => onZoomChange(-0.1)}>
                <Minus className="size-2" />
            </Button>
            <div className="font-semibold text-slate-800 text-xs px-1.5 py-1 border-slate-200 border-r border-l w-12 text-center">
                {(camera.zoom * 100).toFixed(0)}%
            </div>
            <Button size={"sm"} variant={"ghost"} className="py-1 px-3 flex flex-row items-center space-x-2" onClick={(e) => onZoomChange(0.1)}>
                <Plus className="size-2" />
            </Button>
{/* 
            <button onClick={centerCamera}>
                center
            </button> */}
        </div>
    );
};

export default Zoom;
