import { database, functions } from 'services/Firebase';
import { useAuth } from './AuthContext';
import useResponse from './useResponse';
import { httpsCallable } from 'firebase/functions';
import { useWorkspace } from 'hooks/WorkspaceContext';
import Papa from 'papaparse';
import { toast } from 'sonner';

const useDataImporter = () => {
    const { workspace } = useWorkspace();
    const { addResponse } = useResponse();
    const { user } = useAuth();

    const addRow = async (data) => {
        // Check if all required IDs are present
        if (!workspace.id || !user.uid) {
          console.error(
            'Cannot add response: Missing required IDs (workspaceId, userId)'
          );
          return; // Exit function
        }

        const reponseObject = {...data, userId: user.uid, workspaceId: workspace.id}
        const response = await addResponse(reponseObject)
        return {id: response.id, ...reponseObject}
    }

    const importDovetail = (jsonObject, speakers = []) => {
      // console.log("jsonObject", jsonObject)
      // console.log("speakers", speakers)
      const import_dovetail = new Promise((resolve, reject) => {
          var import_dovetail = httpsCallable(functions, 'dovetail_import');
          import_dovetail({ jsonObj: jsonObject, speakers: speakers, workspaceId: workspace.id })
              .then((result) => {
                  // Read result of the Cloud Function.
                  resolve(result.data.result);
              }).catch((error) => {
                  console.error("Error in importDovetail:", error);
                  reject(error.message);
              });
      });
      toast.promise(import_dovetail, {loading: "Importing Dovetail Data",  success: "Added Dovetail Data", error: "Error importing Dovetail Data"})
      return import_dovetail;
    };

  const handleDovetailImport = (file: File, speakers = []) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject(new Error("No file selected"));
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        if (!e.target) {
          reject(new Error("FileReader event target is null"));
          return;
        }

        const text = e.target.result;
        const importSpeakers = speakers.map(item => item.value);

        try {
          const responses = await importDovetail(text, importSpeakers);
          resolve(responses);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);

      reader.readAsText(file);
    });
  };

  const handleImportCsv = (file: File) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject(new Error("No file selected"));
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        if (!e.target) {
          reject(new Error("FileReader event target is null"));
          return;
        }

        const csvString = e.target.result;
        const parsedCsv = Papa.parse(csvString, { header: true });
        const extractedData = parsedCsv.data.map((row) => ({
          note: row.note,
          tags: row.tags?.split(',') || [],
        }));

        try {
          const addRowPromises = extractedData.map(async (row) => {
            const responseObject = {
              answer: row.note,
              metadata: row.tags,
            };
            const response = await addRow(responseObject);
            return { id: response.id, ...responseObject };
          });

          const responses = await Promise.all(addRowPromises);
          resolve(responses);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);

      reader.readAsText(file);
    });
  };

  return {
    handleDovetailImport,
    handleImportCsv,
  };
};
  
export default useDataImporter;