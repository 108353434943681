import { useStorage } from "components/whiteboard/liveblocks.config";

export default () => {
    let sortedTags = {};

    const tags = useStorage((root) => {
        if (!root.layers) return {};
        const tagsMap = {};

        // Iterate over layers to collect metadata
        root.layers.forEach((layer) => {
            (layer.metadata || []).forEach((tag) => {
                if (!tagsMap[tag]) {
                    tagsMap[tag] = [layer.id];
                } else {
                    tagsMap[tag].push(layer.id);
                }
            });
        });

        // Sort tags alphabetically
        const sortedTagsArray = Object.keys(tagsMap).sort();
        sortedTags = sortedTagsArray.reduce((acc, tag) => {
            acc[tag] = tagsMap[tag];
            return acc;
        }, {});

        return tagsMap;
    });

    return () => {
        console.log(sortedTags);
        return sortedTags;
    };
};
