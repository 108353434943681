"use client"

import { Button } from "components/ui/button"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
    PopoverClose,
  } from "components/ui/popover"
import { MessageSquareDashed } from "lucide-react"
import { Label } from "components/ui/label"
import { Textarea } from "components/ui/textarea"
import { useState } from "react"
import useFeedback from "hooks/useFeedback"

interface FeedbackProps {
    isCollapsed?: boolean
}

export default function Feedback({ isCollapsed = false }: FeedbackProps) {
    const [feedback, setFeedback] = useState("");
    const { addFeedback } = useFeedback();

    const handleSubmit = (e) => {
        e.preventDefault();
        if(feedback !== ""){
            addFeedback(feedback).then(() => {
            }).catch((err) => {
                console.log(err)
            })
            setFeedback("")
        }
    }

    return(
    <Popover>
        <PopoverTrigger asChild>
            <Button variant={"white"} size="sm" className="w-full p-0">
              <MessageSquareDashed className="h-3.5 w-3.5" />
              { !isCollapsed && <div className="ml-2">Feedback</div>}
            </Button>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-[320px]">
            <form onSubmit={handleSubmit} className="flex flex-col items-end space-y-4">
            <div className="grid flex-1 w-full gap-4">
                <Label htmlFor="feedback">
                    Submit feedback
                </Label>
                <Textarea
                    id="feedback"
                    name="name"
                    placeholder="Your feedback..."
                    className="h-9"
                    onChange={(e) => setFeedback(e.target.value)}
                    />
            </div>
            <PopoverClose>
                    <Button type="submit" size={"sm"} variant={"white"} disabled={feedback === ""}>
                        Send
                    </Button>
                </PopoverClose>
            </form>
        </PopoverContent>
    </Popover>
    )
}

