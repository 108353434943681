import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import { LiveObject } from "@liveblocks/client";
import { MAX_LAYERS } from "../index";
import { CanvasMode, Layer, Point } from "components/whiteboard/src/types";
import { generateUUID } from "components/whiteboard/src/utils";
import { useWhiteboard } from "components/whiteboard/src/hooks/WhiteboardContext";
import useResponse from "hooks/useResponse";

/**
 * Insert object into the whiteboard
 * 
 * This function creates new objects to the whiteboard with a UID
 * We have two modes:
 * Insert one object at a time
 * Insert multiple objects at once
 */
export default function useInsert() {
    const { setState } = useWhiteboard();
    const { addResponse } = useResponse();
    return useMutation(async ({ self, setMyPresence, storage }, {object, selectAfterInsert = true, insertAtScreenCenter = true} : {object: Layer, selectAfterInsert?: boolean, insertAtScreenCenter?: boolean, insertMultiple?: boolean}) => {
        try {
            const liveLayers = storage.get("layers");
            if (liveLayers.size >= MAX_LAYERS) {
                return;
            }
            const responseobject = await addResponse();
            if(!responseobject) {
                console.error("Failed to add response to Firebase");
                return
            }
            const pasteObjectId = responseobject.id;
            console.log("Created firebase response object with", pasteObjectId)
            const pasteObject : LiveObject<Layer> = new LiveObject(object)

            // Insert the pasted object at the current camera position (if insertAtScreenCenter is true)
            if(insertAtScreenCenter) {
                pasteObject.set("x", self.presence.camera.x - pasteObject.get("width") / 2);
                pasteObject.set("y", self.presence.camera.y - pasteObject.get("height") / 2);
            }

            // Store the pasted object in the storage
            liveLayers.set(pasteObjectId, pasteObject);
            storage.get('layerIds').push(pasteObjectId);

            // Update the user's presence to select the pasted object
            if(selectAfterInsert) setMyPresence({ selection: [pasteObjectId] }, {addToHistory: true});
            setState({ mode: CanvasMode.None });
        } catch (error) {
            console.error('Failed to paste objects:', error);
        }
    }, []);
}