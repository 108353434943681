import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import useResponse from 'hooks/useResponse';
import { useMutation, useMyPresence } from "components/whiteboard/liveblocks.config";
import SimpleMasonry from 'simple-masonry-layout';
import { MAX_LAYERS } from '../index';
import { LiveList, LiveMap, LiveObject } from "@liveblocks/client";
import { CanvasMode, Layer, CanvasState, Color } from "components/whiteboard/src/types";

type WhiteboardContextType = {
  moveIntoClusters: (clusters: string[][], clusterOffset: number, gutter: number) => void;
  canvasState: CanvasState;
  // createObject: (type: string, text?: string) => void;
  handleImport: (responses: any[]) => void;
  setState: (state: CanvasState) => void;
  lastUsedColor: Color;
  setLastUsedColor: (color: Color) => void;
};

interface WhiteboardProviderProps {
  children: ReactNode;
}

const WhiteboardContext = createContext<WhiteboardContextType | null>(null);

export const WhiteboardProvider: React.FC<WhiteboardProviderProps> = ({ children }) => {
    const [canvasState, setState] = useState<CanvasState>({
      mode: CanvasMode.None,
    });
    const [lastUsedColor, setLastUsedColor] = useState<Color>({
      r: 252,
      g: 142,
      b: 42,
    });

    // const createObject = (type: string, text: string = ''): void => {
    //     const shapeId = Date.now().toString();
    //     const shape = new LiveObject({
    //         type: LayerType.Note,
    //         x: myPresence.panning.x,
    //         y: myPresence.panning.y,
    //         sizeX: 250,
    //         sizeY: 250
    //     });

    //     // Assuming storage and setMyPresence are defined elsewhere
    //     // storage.get("objects").set(shapeId, shape);

    //     setMyPresence({ selectedShape: [shapeId] }, { addToHistory: true });
    // };

    const moveIntoClusters = useMutation(
      ({ storage }, clusters: string[][]) => {
        const gutter = 25;
        // Calculate total width needed for each cluster considering max 5 notes per row
        const masonryClusters = clusters.map(cluster => {
          const noteCount = Math.min(cluster.length, 5);
          const noteWidths = cluster.slice(0, noteCount).map(noteId => {
            const note = storage.get("layers").get(noteId) as LiveObject;
            return note ? note.get('width') : 0;
          });
          const width = noteWidths.reduce((a, b) => a + b, 0);

          /*
          // These values could come from anywhere, an api, measuring images in the browser etc.
            const dimensions = [{
              width: 500,
              height: 800
            }, {
              width: 900,
              height: 1000
            },
            ...
            ]
        } */

          const dimensions = cluster.map(noteId => {
              const note = storage.get("layers").get(noteId) as LiveObject;
              return {
                width: note.get('width'),
                height: note.get('height')
              };
            });

          console.log(noteCount, width)
          return SimpleMasonry.generateRectangles({
            dimensions: dimensions,
            columns: noteCount, // Assuming each cluster represents a column
            width: width + (gutter * noteCount), // Example width, adjust according to your layout
            gutter: gutter, // Use the provided gutter value
          });
        });

        // console.log(masonryClusters)
        // Add each cluster to storage with new positions and the total width
        masonryClusters.forEach((cluster, index) => {
          clusters[index].forEach((noteId, i) => {
            console.log(noteId, clusters[index], cluster, i)
            console.log(noteId, cluster[i]);
            // console.log(clusters[index])
            const layers = storage.get("layers");
            layers.get(noteId)?.set("x", cluster[i].x);
            layers.get(noteId)?.set("y", cluster[i].y + index * (250 + gutter));
            layers.get(noteId)?.set("width", 250);
            layers.get(noteId)?.set("height", 250);
            const note = storage.get("layers").get(noteId) as LiveObject;
          });
        })
      }, []
    );
    

    const value: WhiteboardContextType = {
        moveIntoClusters,
        canvasState,
        setState,
        lastUsedColor,
        setLastUsedColor,
    };

    return (
      <WhiteboardContext.Provider value={value}>
        {children}
      </WhiteboardContext.Provider>
  );
};

export const useWhiteboard = (): WhiteboardContextType => {
    const context = useContext(WhiteboardContext);
    if (!context) {
        throw new Error("useWhiteboard must be used within a WhiteboardProvider");
    }
    return context;
};