"use client"

import {
    SettingsIcon,
    Share2,
  } from "lucide-react"
import { Outlet, useNavigate } from "react-router-dom";
import { Nav } from "components/navs/nav"
import { useEffect, useState } from "react";
import { useWindowSize } from "hooks/useWindowSize";

const routes =
[
  {
    title: "General",
    route: "/settings/general",
    // label: "128",
    icon: SettingsIcon,
  },
  {
    title: "Sharing",
    route: "/settings/sharing",
    // label: "128",
    // secondaryIcon: Gem,
    icon: Share2,
  },
//   {
//     title: "Billing",
//     // secondaryIcon: Gem,
//     // icon: LayoutDashboard,
//   }
]

export default () => {
    return(
    <div className="flex flex-row h-full">
        <SidebarContent />
        <div className="lg:p-10 md:p-8 h-full w-full p-4">
            <Outlet />
        </div>
    </div>
    )
}

const SidebarContent = () => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const { width } = useWindowSize();

    useEffect(() => {
      // Define your breakpoint for medium size
      const mediumBreakpoint = 768; // Adjust this value according to your design
  
      // Check if the window width is less than or equal to the medium breakpoint
      if (width <= mediumBreakpoint) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    }, [width]);

    return(
      <div className="flex flex-col h-full border-r border-gray-200 lg:min-w-[200px] md:min-w-[150px]">
          <div className="grow flex flex-col justify-between py-4">
            <Nav
              isCollapsed={isCollapsed}
              links={routes}
            />
        </div>
      </div>
    )
  }