import { database, functions } from 'services/Firebase';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
const useInvite = () => {
  const navigate = useNavigate();
  
  // TODO: See invites and expiration data
  // const [invites, setInvites] = useState([]);

  // useEffect(() => {
  //     if (!user) {
  //         return;
  //     }
  //     const unsubscribeWorkspaces = database.invites.where('workspaceId', '==', workspaceId)
  //     .onSnapshot(snapshot => {
  //         const iniviteData = [];
  //         snapshot.forEach(doc => {
  //           iniviteData.push({ id: doc.id, ...doc.data() });
  //         });
  //         setInvites(workspacesData);
  //     });

  //     return () => {
  //         unsubscribeWorkspaces();
  //     };
  // }, []);

  const joinWorkspace = async (inviteId) => {
    return new Promise((resolve, reject) => {
        var join_workspace = httpsCallable(functions, 'join_workspace');
        join_workspace({ inviteId: inviteId })
            .then((result) => {
                // console.log(result.data);
                // Read result of the Cloud Function.
                resolve(result.data);
                navigate(result.data.workspaceId )

            }).catch((err) => {
              console.error("error", err);
          });
    });
  };
  
    return {
      // invites,
      joinWorkspace,
    };
  };
  
  export default useInvite;