import { functions } from 'services/Firebase';
import { httpsCallable } from 'firebase/functions';
import { useWorkspace } from 'hooks/WorkspaceContext';

const useAnalysis = () => {
    const { workspace } = useWorkspace();

    const clusterAll = async (ids = []) => {
        const clusters = new Promise((resolve, reject) => {
            var cluster_all = httpsCallable(functions, 'cluster_all');
                cluster_all({ workspaceId: workspace.id, ids: ids })
                    .then((result) => {
                        console.log("result", result.data.text);
                        // Read result of the Cloud Function.
                        resolve(result.data.text);
                    }).catch((error) => {
                        console.error("Error in clusterAll:", error);
                        reject(error.message);
                    });
        });
        // toast.promise(clusters, {loading: "Grouping by semantics",  success: "Grouped by semantics successfully", error: "Error grouping by semantics"})
        return clusters;
    };

    const clusterByK = async (ids = [], k = 2) => {
      const clusters = new Promise((resolve, reject) => {
          var cluster_by_k = httpsCallable(functions, 'cluster_by_k');
          cluster_by_k({ workspaceId: workspace.id, ids: ids, k: k })
              .then((result) => {
                //   console.log(result.data);
                  // Read result of the Cloud Function.
                  console.log(result.data)
                  resolve(result.data);
              }).catch((error) => {
                  console.error("Error in clusterByK:", error);
                  reject(error.message);
              });
      });
    //   toast.promise(clusters, {loading: "Grouping by semantics",  success: (data) => {
    //     return toast("Your notes have been structured by semantics",
    //     { 
    //         description: `Created ${data.length} new themes from ${ids.length} notes`,
    //         action: <Button variant="white" size="sm" onClick={() => undo()}>Undo</Button>,
    //     })}, error: "Error while grouping"})

        return clusters;
    };

    const summarize = async (ids = []) => {
        const summary = new Promise((resolve, reject) => {
            var summarize = httpsCallable(functions, 'summarize');
            summarize({ workspaceId: workspace.id, ids: ids })
                .then((result) => {
                    console.log("result", result.data);
                    // Read result of the Cloud Function.
                    resolve(result.data);
                }).catch((error) => {
                    console.error("Error in summarize:", error);
                    reject(error);
                });
        });
        // toast.promise(summary, {
        //     loading: 'Summarizing...',
        //     success: (data) => {
        //       return `Summary has been added`;
        //     },
        //     error: 'Error adding summary'
        //   });        
          return summary;
      };
  
    return {
        clusterAll,
        clusterByK,
        summarize,
    };
  };
  
  export default useAnalysis;