"use client"

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog"
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { useWorkspace } from "hooks/WorkspaceContext";
import { Label } from "components/ui/label";
import { useState } from "react";
import { useAuth } from "hooks/AuthContext";

export const Settings = () => {
    const { workspace, deleteWorkspace, updateWorkspace } = useWorkspace();
    const { user } = useAuth();
    const [newName, setNewName] = useState("");
    const [workspaceName, setWorkspaceName] = useState("");

    const handleRename = async (e) => {
      e.preventDefault();
      if(newName !== "" && newName !== workspace.name)
      await updateWorkspace({name: newName});
    }

    if (workspace)
    return(
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <div className="text-2xl font-semibold px-4">{workspace.name}</div>
            <div className="px-4 text-slate-500">This is a {workspace.type} workspace</div>
          </div>

          <form className="border rounded-md border-slate-200 p-6" onSubmit={handleRename}>
            <div className="font-semibold text-slate-800">
              Rename workspace
            </div>
            <div className="mt-3 text-slate-500">
              Rename your workspace for you and anyone who has access to the workspace.
            </div>
            <Input className="mt-4" placeholder="E.g. your organization, Inc." onChange={(e) => setNewName(e.target.value)}/>
            <Button role="submit" className="mt-4" variant={"secondary"} disabled={newName === "" || newName === workspace.name}>
              Rename
            </Button>
          </form>
  
          <div className="border rounded-md border-slate-200 p-6">
            <div className="font-semibold text-slate-800">
              Delete workspace
            </div>
            <div className="mt-3 text-slate-500">
              Delete your workspace along with all its data, whiteboards and insights.
            </div>
            <Dialog>
              <DialogTrigger disabled={workspace.type === "personal" || workspace.roles[user.uid] !== "owner"}>
                <Button className="mt-4" variant={"destructive"} disabled={workspace.type === "personal" || workspace.roles[user.uid] !== "owner"}>
                  Delete workspace
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Delete workspace</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                  Are you sure you want to delete this workspace? Your workspace will be deleted for you and anyone who has access to this workspace. 
                </DialogDescription>
                <div className="space-y-1">
                  <Label>Type '{workspace.name}' to confirm</Label>
                  <Input placeholder='Type your workspace name' onChange={(e) => setWorkspaceName(e.target.value)}/>
                </div>
                <DialogDescription>
                  This action cannot be undone!
                </DialogDescription>
                <DialogFooter className="flex flex-row justify-between mt-4">
                  <DialogClose>
                    <Button variant={"secondary"}>Cancel</Button>
                  </DialogClose>
                  <DialogClose>
                    <Button variant={"destructive"} onClick={() => deleteWorkspace()} disabled={workspaceName !== workspace.name}>Delete</Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            
          </div>
        </div>
    )
  }