import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import useMoveIntoClusters from "components/whiteboard/src/hooks/useMoveIntoClusters";
import { LayerType } from "components/whiteboard/src/types";

/**
 * 
 */
export default function useCombine () {
    const moveIntoClusters = useMoveIntoClusters();
    return useMutation(async ({ self, storage }) => {
        const selection = self.presence.selection;

        const selectedNotes = selection.filter(id => storage.get("layers").get(id)?.get("type") === LayerType.Note);
        const clusters = [selectedNotes];

        await moveIntoClusters(clusters);
    },[])
}