"use client"

import React, { useState } from "react";
import { Separator } from "components/ui/separator";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Checkbox } from "components/ui/checkbox";
import { Mail } from "lucide-react";
import { Button } from "components/ui/button";
import { useNavigate } from "react-router-dom";
import {
    registerUserWithEmailAndPassword,
    signInWithGoogle
} from "services/Firebase";
import Logo from "components/logo/logo";

export default function SignUp() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const navigate = useNavigate();
    
    const handleRegister = () => {
        const formData = {
            email: email,
            password: password,
        };
        if (email === "" || password === "") {
            alert("Please fill in all fields");
            return;
        }
        if (acceptTerms) {
            registerUserWithEmailAndPassword(formData);
        }
    };

    return (
        <div className="mx-auto lg:w-[25vw] md:w-4/6 w-full px-5 py-auto h-full flex justify-center items-center">
            <div className="flex-col space-y-5 py-5 w-full">
                <div className="flex justify-center items-start flex-col space-y-3">
                    <Logo />
                    <h2>Create your account</h2>
                    <div className="subtle text-slate-500">
                    You’ll be up and running in 2 minutes
                    </div>
                </div>

                <Button className="w-full" onClick={() => signInWithGoogle()}>
                    <Mail className="mr-2 h-4 w-4" /> Continue with Google
                </Button>
                <Separator />
                <Input onChange={(e) => setEmail(e.target.value)} id="emailInput" type="email" label="Email" placeholder="Email"/>
                <Input onChange={(e) => setPassword(e.target.value)} id="passwordInput" type="password" label="Password" placeholder="Password"/>
                <div className="flex items-center space-x-2">
                    <Checkbox id="terms" onClick={() => setAcceptTerms(!acceptTerms)}/>
                    <Label htmlFor="terms">Accept terms and conditions</Label>
                </div>
                <Button onClick={handleRegister} className="w-full" variant="outline">Create account</Button>
                <div className="small">
                    Do you already have an account? <a role="button" onClick={() => navigate('/signin')}>Sign in here</a>
                </div>
            </div>
        </div>
    );
}
