import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import { generateUUID } from "components/whiteboard/src/utils";
import { LiveObject } from "@liveblocks/client";
import { Layer } from "components/whiteboard/src/types";

/**
 * Paste the copied objects from the clipboard
 * 
 * This function retrieves the copied objects from the clipboard and processes them for pasting.
 */
export default function usePaste() {
    const selection = useSelf((me) => me.presence.selection);

    return useMutation(async ({ setMyPresence, storage }) => {
        try {
            // Retrieve the data from the clipboard
            const clipboardData = await navigator.clipboard.readText();
            
            // Split clipboard data into an array of copied object IDs
            const copiedObjectIds = clipboardData.split(',');

            // Add the copied objects to the storage
            copiedObjectIds.forEach(async (copiedObjectId) => {
                // Retrieve the copied object from storage
                const obj = storage.get('layers').get(copiedObjectId);

                // Check if the object exists
                if (obj === undefined || obj === null) return;

                // Generate a unique ID for the pasted object
                const pasteObjectId = generateUUID();
                
                const object : Layer = obj.toObject();
                const pasteObject : LiveObject<Layer> = new LiveObject({
                    ...object,
                    x: object.x + 25,
                    y: object.y + 25,
                    ...(object.originId === undefined || object.originId === null ? { originId: copiedObjectId } : {})
                })

                // Store the pasted object in the storage
                storage.get('layers').set(pasteObjectId, pasteObject);
                storage.get('layerIds').push(pasteObjectId);

                // Update the user's presence to select the pasted object
                setMyPresence({ selection: [pasteObjectId] });
            });
            // console.log('Objects pasted successfully:', copiedObjectIds);
        } catch (error) {
            console.error('Failed to paste objects:', error);
        }
    }, [selection]);
}