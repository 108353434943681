import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import useSelectionBounds from "components/whiteboard/src/hooks/useSelectionBounds";

/**
   * Focus on an object
*/
export default function useGoToObject () {
    return useMutation(
        ({ storage, setMyPresence, self }, { layerId, setSelection = true }: { layerId: string, setSelection?: boolean }) => {
          const layer = storage.get("layers").get(layerId).toObject();
          if (!layer) {
            console.error(`Object with layerId: ${layerId} not found`);
            return;
          }

          const camera = self.presence.camera;
          const x = layer.x + layer.width / 2;
          const y = layer.y + layer.height / 2;

          if(setSelection) {
            setMyPresence({ selection: [layerId] }, {addToHistory: false});
          }
          setMyPresence({ camera: { x: x, y: y, zoom: camera.zoom },  selection: [layerId] }, {addToHistory: false});
    }, []);
}