// useImport.ts
import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import { LiveObject } from "@liveblocks/client";
import { MAX_LAYERS } from "../index";
import { Layer, LayerType, Point } from "components/whiteboard/src/types";
import { generateUUID } from "components/whiteboard/src/utils";
import { useInsertMultiple } from "components/whiteboard/src/hooks/useInsertMultiple";

/**
 * Insert respones as notes into the whiteboard
 * 
 * This function creates new notes and inserts them into the whiteboard.
 * It also updates the user's presence to select the new notes.
 */
export default function useImport() {
    const insertMultiple = useInsertMultiple();

    return useMutation(({ }, responses: any[], source: string) => {
        console.log("useImport", responses);
        // Create an object array to insert
        const objects = responses.map((response) => new LiveObject<Layer>({
            type: LayerType.Note,
            x: 0,
            y: 0,
            width: 250,
            height: 250,
            fill: {
              r: 252,
              g: 142,
              b: 42,
            },
            text: response.answer,
            id: response.id,
            metadata: response.metadata,
            source: source,
        }));

        // Insert the objects into the whiteboard
        insertMultiple({
            objects,
            selectAfterInsert: true,
            insertAtScreenCenter: true,
        });
    }
    , []);
}