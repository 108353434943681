import {
  useMutation,
  RoomProvider,
  useHistory,
  useStorage,
  useSelf,
  useOthersMapped,
  useCanUndo,
  useCanRedo,
  useUndo,
  useRedo,
} from "components/whiteboard/liveblocks.config"
import { ClientSideSuspense } from "@liveblocks/react"
import { LiveList, LiveMap, LiveObject } from "@liveblocks/client"
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  Color,
  Layer,
  LayerType,
  CanvasState,
  CanvasMode,
  Camera,
  Side,
  XYWH,
  Point,
} from "./types"
import styles from "./index.module.css"
import { useParams } from "react-router-dom"
import {
  colorToCss,
  connectionIdToColor,
  findIntersectingLayersWithRectangle,
  penPointsToPathLayer,
  pointerEventToCanvasPoint,
  resizeBounds,
} from "components/whiteboard/src/utils"
import SelectionBox from "./components/SelectionBox"
import LayerComponent from "components/whiteboard/src/components/objects/LayerComponent"
import SelectionTools from "./components/SelectionTools"
import useDisableScrollBounce from "./hooks/useDisableScrollBounce"
import useDeleteLayers from "./hooks/useDeleteLayers"
import MultiplayerGuides from "./components/MultiplayerGuides"
import Path from "components/whiteboard/src/components/objects/Path"
import Toolbar from "./components/toolbar/toolbar"

import { WhiteboardProvider, useWhiteboard } from "./hooks/WhiteboardContext"
import { ControlProvider } from "./hooks/ControlContext"

import { cn } from "lib/utils"
import { generateUUID } from "components/whiteboard/src/utils"
import useCopy from "components/whiteboard/src/hooks/useCopy"
import usePaste from "components/whiteboard/src/hooks/usePaste"
import Zoom from "components/whiteboard/src/components/zoom/zoom"
import { Loading } from "pages/access/loading"
import { EditorNav } from "components/whiteboard/src/components/editor-nav/editor-nav"
import useInsert from "components/whiteboard/src/hooks/useInsert"
import useInsertPath from "components/whiteboard/src/hooks/useInsertPath"

export const MAX_LAYERS = 1024;

export default function Room() {
  const { layoutId } = useParams();

  if(!layoutId) return <Loading />;

  return (
    <RoomProvider
      id={layoutId}
      initialPresence={{
        selection: [],
        focused: false,
        cursor: null,
        pencilDraft: null,
        penColor: null,
        camera: {
          x: 0,
          y: 0,
          zoom: 1,
        },
      }}
      initialStorage={{
        layers: new LiveMap<string, LiveObject<Layer>>(),
        layerIds: new LiveList<string>(),
      }}
    >
      <div className={styles.container}>
        <ClientSideSuspense fallback={<Loading />}>
          {() => 
            <WhiteboardProvider>
                <ControlProvider>
                  <Canvas />
                </ControlProvider>
            </WhiteboardProvider>
            }
        </ClientSideSuspense>
      </div>
    </RoomProvider>
  );
}

function Canvas() {
  const layerIds = useStorage((root) => root.layerIds);
  const { canvasState, setState, lastUsedColor, setLastUsedColor } = useWhiteboard();
  const pencilDraft = useSelf((me) => me.presence.pencilDraft);
  const camera: Camera = useSelf((me) => me.presence.camera);

  const history = useHistory();
  const undo = useUndo();
  const redo = useRedo();

  const canvasRef = useRef<HTMLDivElement>(null);
  const [windowOffset, setWindowOffset] = useState({ left: 0, top: 0 });

  const getPos = () => {
    if (canvasRef.current) {
      const rect = canvasRef.current.getBoundingClientRect() || null;

      if (rect !== null)
        setWindowOffset({
          left: rect.left + window.scrollX,
          top: rect.top + window.scrollY
        });
    }
  };

  useEffect(() => {
    getPos()
    // Also when resizing the window
    window.addEventListener('resize', getPos);
    return () => {
      window.removeEventListener('resize', getPos);
    };
  }, [document.cookie, 'react-resizable-panels:layout']); 

  useDisableScrollBounce();

  const deleteLayers = useDeleteLayers();
  const copy = useCopy();
  const paste = usePaste();

  /**
   * Hook used to listen to Undo / Redo and delete selected layers
   */
  useEffect(() => {
    const preventScroll = (e: WheelEvent) => {
      e.preventDefault();
    };

    // Disable default scrolling behavior in browser
    window.addEventListener('wheel', preventScroll, { passive: false });
    document.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener('wheel', preventScroll, { passive: false });
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [deleteLayers, history]);


  const onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "Backspace": {
        deleteLayers();
        break;
      }
      case "z": {
        if (e.ctrlKey || e.metaKey) {
          if (e.shiftKey) {
            redo();
          } else {
            undo()
          }
          break;
        }
      }
      case "c": {
        if (e.ctrlKey || e.metaKey) {
          copy()
          break;
        }
      }
      case "v": {
        if (e.ctrlKey || e.metaKey) {
          paste();
          break;
        }
      }
      case "d": {
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
          copy()
          paste();
          break;
        }
      }
      // When holding shift
      default: {

      }
    }
  };

  /**
   * Select the layer if not already selected and start translating the selection
   */
  const onLayerPointerDown = useMutation(
    ({ self, setMyPresence }, e: React.PointerEvent, layerId: string) => {
      if (
        canvasState.mode === CanvasMode.Pencil ||
        canvasState.mode === CanvasMode.Inserting
      ) {
        return;
      }

      history.pause();
      e.stopPropagation();
      const point = pointerEventToCanvasPoint(e, camera, windowOffset);
      let newSelection = [layerId];

      // If Shift key is pressed, add to selection
      if (e.shiftKey && self.presence.selection.includes(layerId)) {
        newSelection = self.presence.selection.filter(
          (selectedLayerId) => selectedLayerId !== layerId
        );
        console.log(newSelection)
      } else if (e.shiftKey && !self.presence.selection.includes(layerId)) {
        newSelection = [...self.presence.selection, layerId];
      }

      setMyPresence({ selection: newSelection }, { addToHistory: true });
      setState({ mode: CanvasMode.Translating, current: point });
    },
    [setState, camera, history, canvasState.mode]
  );

  /**
   * Start resizing the layer
   */
  const onResizeHandlePointerDown = useCallback(
    (corner: Side, initialBounds: XYWH) => {
      history.pause();
      setState({
        mode: CanvasMode.Resizing,
        initialBounds,
        corner,
      });
    },
    [history]
  );

  const insertLayer = useInsert()
  const insertPath = useInsertPath();

  /**
   * Move selected layers on the canvas
   */
  const translateSelectedLayers = useMutation(
    ({ storage, self }, point: Point) => {
      if (canvasState.mode !== CanvasMode.Translating) {
        return;
      }

      // Current calculation is to follow mouse. 
      // It would be better to update every frame from the objects
      // current position instead so that the mouse 
      // location on the object does not matter.
      const liveLayers = storage.get("layers");
      for (const id of self.presence.selection) {
        const layer = liveLayers.get(id);
        if (layer) {
          layer.update({
            x: point.x - layer.get("width") / 2,
            y: point.y - layer.get("height") / 2,
          });
        }
      }

      setState({ mode: CanvasMode.Translating, current: point });
    },
    [canvasState]
  );


  /**
   * Resize selected layer. Only resizing a single layer is allowed.
   */
  const resizeSelectedLayer = useMutation(
    ({ storage, self }, point: Point) => {
      if (canvasState.mode !== CanvasMode.Resizing) {
        return;
      }

      const bounds = resizeBounds(
        canvasState.initialBounds,
        canvasState.corner,
        point,
        camera
      );

      const liveLayers = storage.get("layers");
      const layer = liveLayers.get(self.presence.selection[0]);
      if (layer) {
        layer.update(bounds);
      }
    },
    [canvasState]
  );

  const unselectLayers = useMutation(({ self, setMyPresence }) => {
    if (self.presence.selection.length > 0) {
      setMyPresence({ selection: [] }, { addToHistory: true });
    }
  }, []);

  /**
   * Insert the first path point and start drawing with the pencil
   */
  const startDrawing = useMutation(
    ({ setMyPresence }, point: Point, pressure: number) => {
      setMyPresence({
        pencilDraft: [[point.x, point.y, pressure]],
        penColor: lastUsedColor,
      });
    },
    [lastUsedColor]
  );

  /**
   * Continue the drawing and send the current draft to other users in the room
   */
  const continueDrawing = useMutation(
    ({ self, setMyPresence }, point: Point, e: React.PointerEvent) => {
      const { pencilDraft } = self.presence;
      if (
        canvasState.mode !== CanvasMode.Pencil ||
        e.buttons !== 1 ||
        pencilDraft == null
      ) {
        return;
      }

      setMyPresence({
        cursor: point,
        pencilDraft:
          pencilDraft.length === 1 &&
          pencilDraft[0][0] === point.x &&
          pencilDraft[0][1] === point.y
            ? pencilDraft
            : [...pencilDraft, [point.x, point.y, e.pressure]],
      });
    },
    [canvasState.mode]
  );

  /**
   * Start multiselection with the selection net if the pointer move enough since pressed
   */
  const startMultiSelection = useCallback((current: Point, origin: Point) => {
    // If the distance between the pointer position and the pointer position when it was pressed
    if (Math.abs(current.x - origin.x) + Math.abs(current.y - origin.y) > 50) {
      // Start multi selection
      setState({
        mode: CanvasMode.SelectionNet,
        origin,
        current,
      });
    }
  }, []);

  /**
   * Update the position of the selection net and select the layers accordingly
   */
  const updateSelectionNet = useMutation(
    ({ storage, setMyPresence }, current: Point, origin: Point) => {
      const layers = storage.get("layers").toImmutable();
      setState({
        mode: CanvasMode.SelectionNet,
        origin: origin,
        current,
      });
      const ids = findIntersectingLayersWithRectangle(
        layerIds,
        layers,
        origin,
        current
      );
      setMyPresence({ selection: ids });
    },
    [layerIds]
  );

  const selections = useOthersMapped((other) => other.presence.selection);

  /**
   * Create a map layerId to color based on the selection of all the users in the room
   */
  const layerIdsToColorSelection = useMemo(() => {
    const layerIdsToColorSelection: Record<string, string> = {};

    for (const user of selections) {
      const [connectionId, selection] = user;
      for (const layerId of selection) {
        layerIdsToColorSelection[layerId] = connectionIdToColor(connectionId);
      }
    }

    return layerIdsToColorSelection;
  }, [selections]);

  const onWheel = useMutation(
    ({ self, setMyPresence }, e: React.WheelEvent) => {
      if (e.ctrlKey) {
        // Zoom in or out based on the wheel event
        const newZoom = Math.max(0.1, self.presence.camera.zoom - e.deltaY * 0.005);
        setMyPresence({ camera: { ...self.presence.camera, zoom: newZoom } });
      } else {
        // Invert the pan direction by negating deltaX and deltaY
        setMyPresence({
          camera: {
            x: self.presence.camera.x + e.deltaX,
            y: self.presence.camera.y + e.deltaY,
            zoom: self.presence.camera.zoom,
          }
        });
      }
    },
    []
  );

  const onPointerDown = useCallback(
    (e: React.PointerEvent) => {
      const point = pointerEventToCanvasPoint(e, camera, windowOffset);

      if (canvasState.mode === CanvasMode.Inserting) {
        return;
      }

      if (canvasState.mode === CanvasMode.Pencil) {
        startDrawing(point, e.pressure);
        return;
      }

      setState({ origin: point, mode: CanvasMode.Pressing });
    },
    [camera, canvasState.mode, setState, startDrawing]
  );

  const onPointerMove = useMutation(
    ({ setMyPresence }, e: React.PointerEvent) => {
      //e.preventDefault();

      const current = pointerEventToCanvasPoint(e, camera, windowOffset);
      if (canvasState.mode === CanvasMode.Pressing) {
        startMultiSelection(current, canvasState.origin);
      } else if (canvasState.mode === CanvasMode.SelectionNet) {
        updateSelectionNet(current, canvasState.origin);
      } else if (canvasState.mode === CanvasMode.Translating) {
        translateSelectedLayers(current);
      } else if (canvasState.mode === CanvasMode.Resizing) {
        resizeSelectedLayer(current);
      } else if (canvasState.mode === CanvasMode.Pencil) {
        continueDrawing(current, e);
      }
      setMyPresence({ cursor: current });
    },
    [
      camera,
      canvasState,
      continueDrawing,
      resizeSelectedLayer,
      startMultiSelection,
      translateSelectedLayers,
      updateSelectionNet,
    ]
  );

  const onPointerLeave = useMutation(
    ({ setMyPresence }) => setMyPresence({ cursor: null }),
    []
  );

  const onPointerUp = useMutation(
    ({}, e) => {
      const point = pointerEventToCanvasPoint(e, camera, windowOffset);

      if (
        canvasState.mode === CanvasMode.None ||
        canvasState.mode === CanvasMode.Pressing
      ) {
        unselectLayers();
        setState({
          mode: CanvasMode.None,
        });
      } else if (canvasState.mode === CanvasMode.Pencil) {
        insertPath({setState, lastUsedColor});
      } else if (canvasState.mode === CanvasMode.Inserting) {
        insertLayer({object: {
          type: canvasState.layerType,
          x: point.x,
          y: point.y,
          height: 250,
          width: 250,
          fill: lastUsedColor,
          text:  '',
        }, selectAfterInsert: true, insertAtScreenCenter: false});
      } else {
        setState({
          mode: CanvasMode.None,
        });
      }
      history.resume();
    },
    [
      camera,
      canvasState,
      history,
      insertLayer,
      insertPath,
      setState,
      unselectLayers,
    ]
  );

  return (

    <div 
      className={cn("relative h-screen w-full select-none", styles.canvas)}
      ref={canvasRef}
    >
    {/* <ContextMenu>
      <ContextMenuTrigger> */}
          <div className={cn("absolute right-0 top-0")} >
            <SelectionTools
              isAnimated={
                canvasState.mode !== CanvasMode.Translating &&
                canvasState.mode !== CanvasMode.Resizing
              }
              camera={camera}
              setLastUsedColor={setLastUsedColor}
            />
              <svg
                className={cn(styles.renderer_svg)}
                onWheel={onWheel}
                onPointerDown={onPointerDown}
                onPointerMove={onPointerMove}
                onPointerLeave={onPointerLeave}
                onPointerUp={onPointerUp}>
                <g
                style={{
                  transform: `scale(${camera.zoom})`,
                  transformOrigin: "center",
                  transition: 'transform ease-in-out',
                }} >
                <g 
                style={{
                  transform: `translate(${-camera.x + window.innerWidth/2}px, ${-camera.y + window.innerHeight/2}px )`,
                  transformOrigin: "center",
                  transition: 'transform ease-in-out',
                }} 
                >

                  {/* Objects */}
                  <g>
                    {layerIds.map((layerId) => (
                      <LayerComponent
                        key={layerId}
                        id={layerId}
                        mode={canvasState.mode}
                        onLayerPointerDown={onLayerPointerDown}
                        selectionColor={layerIdsToColorSelection[layerId]}
                      />
                    ))}
                    {/* Blue square that show the selection of the current users. Also contains the resize handles. */}
                    <SelectionBox
                      onResizeHandlePointerDown={onResizeHandlePointerDown}
                    />
                    {/* Selection net that appears when the user is selecting multiple layers at once */}
                    {canvasState.mode === CanvasMode.SelectionNet &&
                      canvasState.current != null && (
                        <rect
                          className={cn("stroke-[#9747ff] fill-[#9747ff]/15")}
                          x={Math.min(canvasState.origin.x, canvasState.current.x)}
                          y={Math.min(canvasState.origin.y, canvasState.current.y)}
                          width={Math.abs(canvasState.origin.x - canvasState.current.x)}
                          height={Math.abs(
                            canvasState.origin.y - canvasState.current.y
                          )}
                        />
                      )}
                    {/* Drawing in progress. Still not commited to the storage. */}
                    {pencilDraft != null && pencilDraft.length > 0 && (
                      <Path
                        points={pencilDraft}
                        fill={colorToCss(lastUsedColor)}
                        x={0}
                        y={0}
                      />
                    )}
                  </g>
                  <g>
                    <MultiplayerGuides />
                  </g>
                </g>
                </g>
              </svg>
              
          </div>
          
        {/* <NameBar/> */}
        {/* <AvatarStack /> */}
        <EditorNav />
        <Toolbar 
          canvasState={canvasState}
          setCanvasState={setState}/>
        <Zoom />

        {/* <div className={
            cn(
              "absolute z-40 w-[200px] bg-white flex flex-col absolute p-4 m-4 bottom-0 left-1 border border-gray-200 bg-white rounded-lg shadow-sm",
            
            )
          }>
              Panning: {camera?.x.toFixed(0)}, {camera?.y.toFixed(0)}
              <br/>
              Zoom: {camera?.zoom.toFixed(2)}
              <br/>
              canvasState.mode: {canvasState.mode}
          </div> */}
        {/* </ContextMenuTrigger>
      <CanvasContextMenu />
    </ContextMenu> */}
  </div>
  );
}