import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";
import { getAuth, updateProfile, UserCredential, User } from "firebase/auth";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseError } from '@firebase/util';
import { NavigateFunction } from 'react-router-dom';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);

export const firestore = app.firestore();
export const functions = getFunctions(app);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);

export const auth = firebase.auth();
export const user = auth.currentUser;

export const database = {
    users: firestore.collection("users"),
    forms: firestore.collection("forms"),
    participants: firestore.collection("participants"),
    questions: firestore.collection("questions"),
    responses: firestore.collection("responses"),
    studies: firestore.collection("studies"),
    workspaces: firestore.collection("workspaces"),
    insights: firestore.collection("insights"),
    layouts: firestore.collection("layouts"),
    feedback: firestore.collection("feedback"),
    invites: firestore.collection("invites"),
    
    formatDoc: (doc: firebase.firestore.DocumentSnapshot) => {
        return { id: doc.id, ...doc.data() };
    },
    getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
};

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
    console.log('Signing in...');
    auth.signInWithPopup(googleProvider).then((res) => {
        // If this is the first time the user is logging in
        if(res.additionalUserInfo?.isNewUser){
            createUserAndRedirect();
        }else{
            window.location.href='../';
        }
    }).catch((error: FirebaseError) => {
        alert(error.message);
    });
};

const createUserAndRedirect = async () => {
    // If the user is logged in, create the user
    if(!auth.currentUser) return;

    let displayName = "";
    if(!auth.currentUser?.displayName || auth.currentUser.displayName === "" || auth.currentUser.displayName.split(" ")[0] === "undefined"){
        displayName = "My Personal";
    }else{
        displayName = auth.currentUser.displayName.split(" ")[0] + "'s";
    }
    database.workspaces.add({
        name: `${displayName} Workspace`,
        roles: {
            [auth.currentUser.uid]: "owner"
        },
        type: 'personal',
        createdAt: database.getCurrentTimestamp(),
    }).then((docRef) => {
        const data = {
            email: auth.currentUser?.email,
            createdAt: database.getCurrentTimestamp(),
            workspaceId: docRef.id,
        };
        
        // If the user is logged in, create the user
        if(!auth.currentUser) return;

        database.users.doc(auth.currentUser.uid).set(data).then(() => {
            console.log("Document successfully written!");
            window.location.href='../';
        }).catch((error: FirebaseError) => {
            alert("Error writing document: " + error);
        });
    });
};

export const signOut = () => {
    console.log('Signing Out');
    firebase.auth().signOut()
        .then(() => {
            // Sign-out successful.
            console.log('Sign-out successful');
            console.log(auth.currentUser);
            window.location.href='../';
        })
        .catch((error: FirebaseError) => {
            alert(error.message);
        });
};

export const registerUserWithEmailAndPassword = (formData: { email: string; password: string; firstName: string; lastName: string; }) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
  
      createUserWithEmailAndPassword(auth, formData.email, formData.password)
        .then((userCredential: UserCredential) => {
          console.log("Created profile");
  
          updateProfile(auth.currentUser!, {
            displayName: `${formData.firstName} ${formData.lastName}`,
          })
            .then(() => {
                createUserAndRedirect();
                resolve("User registered and profile updated successfully");
            })
            .catch((error: FirebaseError) => {
              alert(error);
              reject(error);
            });
        })
        .catch((error: FirebaseError) => {
            alert(error);
            // Check if the error is due to an existing user with the same email address
            if (error.code === 'auth/email-already-in-use') {
                reject("Email address is already in use. Please choose a different email.");
            } else {
                reject(error);
            }
        });
    });
};

export const signInWithEmailAndPasswordPersistent = (email: string, password: string) => {
    console.log('Persistent sign in with email');
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
            signInWithEmailAndPassword(email, password);
        });
};

export const signInWithEmailAndPassword = (email: string, password: string) => {
    console.log('Signing in with email');
    auth.signInWithEmailAndPassword(email, password).then((userCredential: UserCredential) => {
        window.location.href='../';
    })
    .catch((error: FirebaseError) => {
        alert(error.message);
    });
};

export const forgotPassword = (email: string, sendEmailCallback: (message: string) => void) => {
    console.log('Forgot password, sending email to ' + email);
    auth.sendPasswordResetEmail(email).then(() => {
        sendEmailCallback('Email sent to ' + email);
        window.location.href = '../Projects';
    })
    .catch((error: FirebaseError) => {
        alert(error.message);
    });
};

export const verifyPassword = (email: string, password: string, responseCallback: (message: string) => void) => {
    console.log('Verifying password for ' + email);
    auth.signInWithEmailAndPassword(email, password).then(() => {
        responseCallback('success');
    })
    .catch((error: FirebaseError) => {
        responseCallback(error.message);
    });
};

export const updatePassword = (currentUser: User, oldEmail: string, oldPassword: string, newPassword: string, errorCallback?: (message: string) => void) => {
    console.log('Updating password for ' + oldEmail + ' to ' + newPassword);
    auth.signInWithEmailAndPassword(oldEmail, oldPassword).then(() => {
        auth.currentUser?.updatePassword(newPassword).then(() => {
            window.location.href='/Projects';
        })
        .catch((error: FirebaseError) => {
            if(errorCallback) { 
                errorCallback(error.message); 
            } else {
                alert(error.message);
            }
        });
    })
    .catch((error: FirebaseError) => {
        if(errorCallback) { 
            errorCallback(error.message); 
        } else {
            alert(error.message);
        }
    });
};

export const getProducts = (): Promise<any[]> => {
    return new Promise((resolve, reject) => {
        let plansWithPrices: any[] = [];

        database.products
            .where('active', '==', true)
            .get()
            .then(async (querySnapshot) => {
                for (const doc of querySnapshot.docs) {
                    let plan = doc.data();
                    const priceSnap = await doc.ref.collection('prices').get();
                    let prices: any[] = [];
                    priceSnap.docs.forEach((doc) => {
                        const priceData = doc.data();
                        const priceWithId = { id: doc.id, ...priceData };
                        prices = [...prices, priceWithId];
                    });
                    // Sort prices by interval, with 'month' before 'year'
                    prices.sort((a, b) => {
                        if (a.interval === b.interval) {
                            return 0;
                        } else if (a.interval === 'month') {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                    plan.prices = prices;
                    plansWithPrices = [...plansWithPrices, plan];
                }
                resolve(plansWithPrices);
            })
            .catch((error: FirebaseError) => reject(error));
    });
};

export const createCheckout = async (price: string) => {
    console.log(price);

    const docRef = await database.users
        .doc(auth.currentUser?.uid)
        .collection('checkout_sessions')
        .add({
            price: price,
            allow_promotion_codes: true,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        });

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        console.log(snap.data());
        if (error) {
            console.error(`An error occurred: ${error.message}`);
        }
        if (url) {
            window.location.assign(url);
        }
    });
};

export const openCustomerPortal = async () => {
    const functionLocation = "us-central1"; // e.g., us-central1
    const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
};