"use client"

import React, { useState } from "react";
import { Separator } from "components/ui/separator";
import { Input } from "components/ui/input";
import { Mail } from "lucide-react";
import { Button } from "components/ui/button";
import { useNavigate } from "react-router-dom";
import {
    signInWithEmailAndPassword,
    signInWithGoogle
} from "services/Firebase";
import Logo from "components/logo/logo";

export default function SignUp() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    
    return (
        <div className="mx-auto lg:w-[25vw] md:w-4/6 w-full px-5 py-auto h-full flex justify-center items-center">
            <div className="flex-col space-y-5 py-5 w-full">
                <div className="flex justify-center items-start flex-col space-y-3">
                    <Logo />
                    <h2>Sign in</h2>
                </div>

                <Button className="w-full" onClick={() => signInWithGoogle()}>
                    <Mail className="mr-2 h-4 w-4" /> Continue with Google
                </Button>
                <Separator />
                <Input onChange={(e) => setEmail(e.target.value)} id="emailInput" type="email" label="Email" placeholder="Email"/>
                <Input onChange={(e) => setPassword(e.target.value)} id="passwordInput" type="password" label="Password" placeholder="Password"/>
                <Button onClick={() => signInWithEmailAndPassword(email, password)} className="w-full" variant="outline">Sign in</Button>
                <div className="small">
                    Don’t have an account yet? <a role="button" onClick={() => navigate('/signup')}>Get started here</a>
                </div>
            </div>
        </div>
    );
}
