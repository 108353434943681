import { useHistory, useMutation, useSelf, useUndo } from "components/whiteboard/liveblocks.config";
import useAnalysis from "components/whiteboard/src/hooks/useAnalysis";
import useMoveIntoClusters from "components/whiteboard/src/hooks/useMoveIntoClusters";
import { LayerType } from "components/whiteboard/src/types";
import { toast } from "sonner";
import { Button } from "components/ui/button";

/**
 * Select the layer if not already selected and start translating the selection
 * 
 * This function retrieves the selected objects from the storage and prepares them for copying to the clipboard.
 * It converts the selected objects into a string representation and writes it to the clipboard using the
 * navigator.clipboard.writeText() method.
 */
export default function useGroupBySemantics () {
    const { clusterByK } = useAnalysis();
    const moveIntoClusters = useMoveIntoClusters();
    const undo = useUndo();

    return useMutation(async ({ self, storage }) => {
        const groupBySemantics = new Promise(async (resolve, reject) => {
            try{
                const selection = self.presence.selection;
                const totalPoints = selection.length/* Number of selected points */;

                // Calculate the minimum and maximum number of clusters based on the desired range of points per cluster
                const minClusters = Math.ceil(totalPoints / 5); // Minimum number of clusters
                const maxClusters = Math.ceil(totalPoints / 3); // Maximum number of clusters

                // Choose the number of clusters within the range
                const k = Math.max(3, Math.min(maxClusters, /* Some logic to choose a number of clusters between minClusters and maxClusters */));

                const selectedNotes = selection.filter(id => storage.get("layers").get(id)?.get("type") === LayerType.Note);
                const clusters = selectedNotes.length > 3 ? await clusterByK(selectedNotes, k) : [selectedNotes];

                await moveIntoClusters(clusters)
                resolve({k: k, clusters: clusters});
            } catch (error) {
                reject(error);
            }
        });

        return toast.promise(groupBySemantics, {
            loading: "Grouping by semantics",  
            success: (data) => {
                return toast("Your notes have been structured by semantics",
                { 
                    
                    description: `Created ${data.clusters.length} new themes from ${data.k} notes`,
                    action: <Button variant="white" size="sm" onClick={() => undo()}>Undo</Button>,
                })
            }, 
            error: "Error while grouping"})
    },[])
}