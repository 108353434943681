import { Separator } from "components/ui/separator";
import { LayoutDashboard, PlusIcon } from "lucide-react";

import { Button } from "components/ui/button";
import useLayouts from "hooks/useLayouts";
import { timeAgo } from "hooks/useDate"
import { useNavigate, useParams } from "react-router-dom";

import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover"

import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuShortcut,
    ContextMenuTrigger,
  } from "components/ui/context-menu"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "components/ui/dialog"
import * as DialogPrimitive from "@radix-ui/react-dialog";

import { useEffect, useState } from "react";
import NoWhiteboards from "components/empty-states/no-whiteboards";

export default () => {
    const { layouts, loading } = useLayouts()

    return(
    <div className="flex sm:h-svh h-full px-3 py-3 md:px-5 md:py-7 lg:px-9 lg:py-12 overflow-auto">
        <div className="flex flex-col space-y-6 w-full h-full">
            <div className="flex flex-col space-y-[18px] h-full">
                <div className="flex flex-row justify-between items-center h-[40px]">
                    <div className="space-x-2 flex flex-row justify-center items-center">
                        <LayoutDashboard className="h-6 w-6" />
                        <h3 className="text-slate-900 dark:text-slate-200 my-auto">Whiteboards</h3>
                    </div>
                    <CreateNewWhiteboard/>
                </div>
                <Separator />
                <div className="grid md:grid-cols-3 xl:grid-cols-4 sm:gride-cols-2 gap-3">
                    {layouts.slice() // Create a copy of the array to avoid mutating the original
                        .sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds) // Sort the layouts by createdAt.seconds
                        .map((layout, index) => (
                        // If layout.type is whiteboard
                        <Whiteboard layout={layout} key={index} />
                    ))}
                </div>
                {layouts?.length === 0 && !loading && <NoWhiteboards />}
            </div>
        </div>
    </div>
    )
}

const LayoutContextMenu = (props) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const down = (e) => {
        if (e.key === "r" && (e.metaKey || e.ctrlKey)) {
            e.preventDefault()
            setOpen((open) => !open)
        }
        }
        document.addEventListener("keydown", down)
        return () => document.removeEventListener("keydown", down)
    }, [])

    const onRename = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const name = formData.get("name")
        props.onRename(name)
    }


    return(
        <ContextMenu>
            <ContextMenuTrigger asChild>
                {props.children}
            </ContextMenuTrigger>
            <ContextMenuContent className="flex flex-col">
                <Dialog>
                    <DialogTrigger>
                        <ContextMenuItem onSelect={(e) => e.preventDefault()}>
                            Rename
                        </ContextMenuItem>
                    </DialogTrigger>
                    <DialogContent>
                        <form onSubmit={onRename} >
                        <DialogHeader>
                            <DialogTitle>Rename whiteboard</DialogTitle>
                            <DialogDescription>
                            You can rename your whiteboard here. This will change the name for anyone in the workspace.
                            <div className="grid flex-1 gap-2 py-4">
                                <Label htmlFor="link" className="sr-only">
                                Name
                                </Label>
                                <Input
                                id="link"
                                defaultValue={props.layout?.name || ""}
                                name="name"
                                type="text"
                                placeholder="Enter a name"
                                className="h-9"
                                />
                            </div>
                        </DialogDescription>
                        </DialogHeader>
                        
                        <DialogFooter className="flex flex-row justify-end w-full">
                            <DialogPrimitive.Close >
                                <Button 
                                size={"sm"}
                                role="submit"
                                onSubmit={(e) => onSubmit(e)}>Rename</Button>
                            </DialogPrimitive.Close>
                        </DialogFooter>
                        </form>

                    </DialogContent>
                </Dialog>
                <ContextMenuItem onSelect={(e) => props.onDuplicate(e)}>
                    Duplicate
                </ContextMenuItem>
                <Dialog>
                    <DialogTrigger>
                        <ContextMenuItem onSelect={(e) => e.preventDefault()}>
                        Delete
                        </ContextMenuItem>
                    </DialogTrigger>
                    <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Are you sure absolutely sure?</DialogTitle>
                        <DialogDescription>
                            This action cannot be undone. This will permanently delete
                            your whiteboard and remove your data from our servers.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className="flex flex-row justify-between w-full">
                        <DialogPrimitive.Close >
                            <Button 
                            variant={"link"} 
                            size={"sm"}
                            onClick={() => {}}>Cancel</Button>
                        </DialogPrimitive.Close>
                        <DialogPrimitive.Close >
                            <Button onClick={() => props.onDelete()} 
                            className="ml-auto bg-red-500 hover:bg-red-600" 
                            size={"sm"}
                            >Delete</Button>
                        </DialogPrimitive.Close>
                    </DialogFooter>
                    </DialogContent>
                </Dialog>
            </ContextMenuContent>
            </ContextMenu>
    )
}

const Whiteboard = ({layout}) => {
    const { deleteLayout, updateLayout, duplicateLayout } =  useLayouts()
    const navigate = useNavigate();
    const { workspaceId } = useParams();

    if(layout.type !== "whiteboard") return(null)
    return( 
        <LayoutContextMenu 
        onDelete={() => deleteLayout(layout.id)} 
        onDuplicate={() => duplicateLayout(layout.id)} 
        onRename={(name) => {updateLayout(layout.id, {name: name})}} 
        layout={layout}>
            <div role="button"
                onClick={() => {
                    navigate('/' + workspaceId + '/' + layout.type + '/' + layout.id)
                }}
                className="overflow-hidden rounded-xl border border-slate-200 flex flex-col w-full lg:max-w-[500px]">
                <svg width="416" height="166" viewBox="0 0 416 166" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="416" height="166" fill="transparant"/>
                    <path d="M342 110C342 107.791 343.791 106 346 106H386C388.209 106 390 107.791 390 110V150C390 152.209 388.209 154 386 154H346C343.791 154 342 152.209 342 150V110Z" fill="#E2E8F0"/>
                    <path d="M256 16C256 13.7909 257.791 12 260 12H300C302.209 12 304 13.7909 304 16V56C304 58.2091 302.209 60 300 60H260C257.791 60 256 58.2091 256 56V16Z" fill="#E2E8F0"/>
                    <path d="M38 40C38 37.7909 39.7909 36 42 36H82C84.2091 36 86 37.7909 86 40V80C86 82.2091 84.2091 84 82 84H42C39.7909 84 38 82.2091 38 80V40Z" fill="#E2E8F0"/>
                    <path d="M135 110C135 107.791 136.791 106 139 106H179C181.209 106 183 107.791 183 110V150C183 152.209 181.209 154 179 154H139C136.791 154 135 152.209 135 150V110Z" fill="#E2E8F0"/>
                </svg>
                <div className="flex flex-row space-x-3 p-2 border-t border-slate-200">
                    <div className=" rounded-sm border border-slate-200 p-3">
                        <LayoutDashboard className="h-4 w-4"/>
                    </div>
                    <div className="flex flex-col">
                        <h3 className="font-bold text-sm">{layout.name}</h3>
                        <p className="text-xs text-slate-500">
                            {timeAgo(layout.createdAt)}
                        </p>
                    </div>
                </div>
            </div>
        </LayoutContextMenu>
    )
}

export function CreateNewWhiteboard(props, {onCreate = () => {}}) {
    const {addLayout} = useLayouts();

    const createNew = (e) => {
        e.preventDefault();
        addLayout({name: e.target.name.value});
        onCreate();
    }

    return (
    <Popover>
        <PopoverTrigger>
            <Button size="sm" {...props}>Create new</Button>
        </PopoverTrigger>
        <PopoverContent align="end" className="w-[320px]">
            <div className="flex flex-col space-y-2 text-center sm:text-left">
            <h3 className="text-lg font-semibold">Create new whiteboard</h3>
            <p className="text-sm text-muted-foreground">
                Anyone in your workspace will be able to view and edit this whiteboard.
            </p>
            </div>
            <form onSubmit={createNew} className="flex items-center space-x-2 pt-4">
            <div className="grid flex-1 gap-2">
                <Label htmlFor="link" className="sr-only">
                Link
                </Label>
                <Input
                id="link"
                defaultValue="New whiteboard"
                name="name"
                type="text"
                placeholder="Enter a name"
                className="h-9"
                />
            </div>
            <Button type="submit" size="sm" className="px-3">
                <span className="sr-only">Create</span>
                <PlusIcon className="h-4 w-4" />
            </Button>
            </form>
        </PopoverContent>
    </Popover>
    )
}