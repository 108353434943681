import React, { FC } from 'react';

export const Cluster: FC = (props: any) => {
    return(
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="9" height="9" rx="2" stroke="currentColor" strokeWidth="2"/>
        <rect x="2" y="14" width="9" height="9" rx="2" stroke="currentColor" strokeWidth="2"/>
        <rect x="14" y="2" width="9" height="9" rx="2" stroke="currentColor" strokeWidth="2"/>
    </svg>
    )
}