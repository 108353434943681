import React, { useContext, useState, useEffect } from "react";
import { database, functions } from 'services/Firebase';
import { useAuth } from "hooks/AuthContext";
import { useToast } from "hooks/ToastContext";
import { httpsCallable } from 'firebase/functions';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import useDocumentTitle from "hooks/useDocumentTitle";

const WorkspaceContext = React.createContext();

export function useWorkspace() {
    return useContext(WorkspaceContext);
}

export function WorkspaceProvider({ children }) {
    const [workspaces, setWorkspaces] = useState([]);
    const { user } = useAuth();
    const [workspace, setWorkspace] = useState();
    const { workspaceId } = useParams();
    const { notify, error } = useToast();

    const navigate = useNavigate();
    useDocumentTitle(workspace?.name ? workspace.name : "Leapfrog");
    
    useEffect(() => {
        !workspaceId && workspaces.length > 0 && navigate("/" + workspaces[0].id + "/whiteboards");
        const fetchworkspace = async () => {
            if(workspaceId) {
                const workspace = await getWorkspace(workspaceId);
                setWorkspace(workspace);
            }
        }

        fetchworkspace();
    }, [workspaces, workspaceId]);

    useEffect(() => {
        if (!user) {
            return;
        }
        const unsubscribeWorkspaces = database.workspaces.where('roles.' + user.uid, 'in', ['owner', 'editor'])
        // .orWhere('roles.' + user.uid, '==', 'viewer');
        .onSnapshot(snapshot => {
            const workspacesData = [];
            snapshot.forEach(doc => {
                workspacesData.push({ id: doc.id, ...doc.data() });
            });
            setWorkspaces(workspacesData);
        });

        return () => {
            unsubscribeWorkspaces();
        };
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }
        if(workspaces.length > 0 && !workspace) {
            setWorkspace(workspaces[0]);
        }
    }, [workspaces]);

    const createWorkspace = async (workspaceName) => {
        try {
            const workspaceRef = await database.workspaces.add({
                name: workspaceName || user.displayName + "'s Workspace",
                roles: {
                    [user.uid]: "owner"
                },
                type: 'shared',
                createdAt: database.getCurrentTimestamp(),
                createdBy: user.uid,
            });
            setSelectedWorkspace(workspaceRef.id);
        } catch (error) {
            console.error("Error creating workspace:", error);
        }
    };

    const getWorkspace = async (workspaceId) => {
        return new Promise((resolve, reject) => {
            database.workspaces.doc(workspaceId).get()
                .then(workspaceDoc => {
                    if (workspaceDoc.exists) {
                        resolve({ id: workspaceDoc.id, ...workspaceDoc.data() });
                    } else {
                        console.log('Workspace not found!');
                        reject('Workspace not found!');
                    }
                })
                .catch(error => {
                    console.error('Error getting workspace:', error);
                    reject(error);
                });
        });
    };

    const updateWorkspace = async (newData, workspaceId = workspace.id) => {
        try {
            await database.workspaces.doc(workspaceId).update(newData);
        } catch (error) {
            console.error("Error updating workspace:", error);
        }
    };

    const deleteWorkspace = async (workspaceId = workspace.id) => {
        return new Promise((resolve, reject) => {
            var delete_workspace = httpsCallable(functions, 'delete_workspace');
            delete_workspace({ workspaceId: workspaceId })
                .then((result) => {
                    console.log("result", result.data.text);
                    // Read result of the Cloud Function.
                    notify("Deleted Workspace");
                    resolve(result.data.text);
                }).catch((err) => {
                  console.error("error", err);
                  error(err.message)
              });
        });
    };

    const inviteToWorkspace = async (email, workspaceId = workspace.id) => {
        return new Promise((resolve, reject) => {
            var send_invite = httpsCallable(functions, 'send_invite');
            send_invite({ workspaceId: workspaceId, email: email })
                .then((result) => {
                    // Read result of the Cloud Function.
                    resolve(result.data);
                }).catch((err) => {
                    reject(err.message);
              });
        });
    };

    const removeFromWorkspace = async (userId, workspaceId = workspace.id) => {
        return new Promise((resolve, reject) => {
            var remove_from_workspace = httpsCallable(functions, 'remove_from_workspace');
            remove_from_workspace({ workspaceId: workspaceId, userIdToRemove: userId })
                .then((result) => {
                    console.log("result", result.data.text);
                    // Read result of the Cloud Function.
                    notify("Removed from workspace");
                    resolve(result.data);
                }).catch((err) => {
                  console.error("error", err);
                  error(err.message)
              });
        });
    };

    const value = {
        workspaces,
        workspace, 
        setWorkspace,
        createWorkspace,
        getWorkspace,
        updateWorkspace,
        deleteWorkspace,
        inviteToWorkspace,
        removeFromWorkspace,
    };

    return (
        <WorkspaceContext.Provider value={value}>
            {children}
        </WorkspaceContext.Provider>
    );
}
