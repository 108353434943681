import Logo from "components/logo/logo";
import { Spinner } from "components/ui/spinner";

export function Loading() {
  return (
    <div className="h-screen w-full bg-gray-50 p-6">
        <div className="fixed bottom-6 flex flex-row gap-x-3 mt-auto items-center">
            <Spinner />
            <div className="text-sm text-gray-500">
                Loading Leapfrog
            </div>
        </div>
        <div className={'flex flex-row items-center justify-center h-full w-full'}>
            <Logo showName={true} />
        </div>
    </div>

  );
}