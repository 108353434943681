import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";

/**
   * Move all the selected layers to the front
  */
export default function useMoveToFront () {
    const selection = useSelf((me) => me.presence.selection);
    return useMutation(
      ({ storage }) => {
        const liveLayerIds = storage.get("layerIds");
        const indices: number[] = [];
  
        const arr = liveLayerIds.toArray();
  
        for (let i = 0; i < arr.length; i++) {
          if (selection.includes(arr[i])) {
            indices.push(i);
          }
        }
  
        for (let i = indices.length - 1; i >= 0; i--) {
          liveLayerIds.move(
            indices[i],
            arr.length - 1 - (indices.length - 1 - i)
          );
        }
      },
      [selection]
    );
}