import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation, useMyPresence } from "components/whiteboard/liveblocks.config";

interface ControlContextType {
  zoomFactor: number;
  onZoomIn: () => void;
  onZoomOut: () => void;
}

const ControlContext = createContext<ControlContextType | undefined>(undefined);

export const ControlProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [zoom, setZoom] = useState<number>(1);
  const minZoomFactor: number = 0.2; // Minimum zoom factor
  const maxZoomFactor: number = 8.0; // Maximum zoom factor
  const zoomStep: number = 0.1; // Zoom step for each scroll event

  useEffect(() => {
    // onZoomChange();

    // // Add event listener for wheel events
    // const zoomWheel = (e: WheelEvent) => {
    //   const newZoomFactor = zoom - (e.deltaY * 0.0002); // Adjust the zoom step as per your requirement
    //   // Update zoom factor within the limits
    //   if (newZoomFactor >= minZoomFactor && newZoomFactor <= maxZoomFactor) {
    //     setZoom(newZoomFactor);
    //   }
    // };
    // document.addEventListener('wheel', zoomWheel);
    // // Cleanup function to remove the event listener when component unmounts
    // return () => {
    //   document.removeEventListener('wheel', zoomWheel);
    // };
  }, [zoom]); // Dependency array to ensure re-attachment when zoomFactor changes

  const onZoomChange = useMutation(
    ({ self, setMyPresence }, delta: number) => {
      // Pan the camera based on the wheel delta
      setMyPresence({ camera : {
          x: self.presence.camera.x,
          y: self.presence.camera.y,
          zoom: zoom + delta,
        }
      });
    },[zoom]);

  const onZoomOut = () => {
    const newZoomFactor = zoom + zoomStep;
    if (newZoomFactor <= maxZoomFactor) {
      setZoom(newZoomFactor);
    }
  };

  const onZoomIn = () => {
    const newZoomFactor = zoom - zoomStep;
    if (newZoomFactor >= minZoomFactor) {
      setZoom(newZoomFactor);
    }
  };

  const value: ControlContextType = {
    zoomFactor: zoom,
    onZoomIn,
    onZoomOut,
    onZoomChange,
  };

  return (
    <ControlContext.Provider value={value}>
      {children}
    </ControlContext.Provider>
  );
};

export const useControls = (): ControlContextType => {
  const context = useContext(ControlContext);
  if (context === undefined) {
    throw new Error('useControls must be used within a ControlProvider');
  }
  return context;
};