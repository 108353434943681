import { useStorage } from "components/whiteboard/liveblocks.config";
import React, { memo } from "react";
import Ellipse from "components/whiteboard/src/components/objects/Ellipse";
import Path from "components/whiteboard/src/components/objects/Path";
import Rectangle from "components/whiteboard/src/components/objects/Rectangle";
import Theme from "components/whiteboard/src/components/objects/Theme";

import { CanvasMode, LayerType } from "components/whiteboard/src/types";
import { colorToCss } from "components/whiteboard/src/utils";
import Note from "components/whiteboard/src/components/objects/Note";
import Summary from "components/whiteboard/src/components/objects/Summary";
import BlankObject from "components/whiteboard/src/components/objects/BlankObject";

type Props = {
  id: string;
  mode: CanvasMode;
  onLayerPointerDown: (e: React.PointerEvent, layerId: string) => void;
  selectionColor?: string;
};

const LayerComponent = memo(
  ({ mode, onLayerPointerDown, id, selectionColor }: Props) => {
    const layer = useStorage((root) => root.layers.get(id));
    if (!layer) {
      return null;
    }

    const isAnimated =
      mode !== CanvasMode.Translating && mode !== CanvasMode.Resizing;

    switch (layer.type) {
      case LayerType.Ellipse:
        return (
          <Ellipse
            id={id}
            layer={layer}
            onPointerDown={onLayerPointerDown}
            selectionColor={selectionColor}
          />
        );
      case LayerType.Path:
        return (
          <Path
            key={id}
            points={layer.points}
            onPointerDown={(e) => onLayerPointerDown(e, id)}
            x={layer.x}
            y={layer.y}
            fill={layer.fill ? colorToCss(layer.fill) : "#CCC"}
            stroke={selectionColor}
          />
        );
      case LayerType.Rectangle:
        return (
          <Rectangle
            id={id}
            layer={layer}
            onPointerDown={onLayerPointerDown}
            selectionColor={selectionColor}
          />
        );
      case LayerType.Theme:
        return (
          <Theme
            id={id}
            layer={layer}
            onPointerDown={onLayerPointerDown}
            selectionColor={selectionColor}
          />
        );
      case LayerType.Note:
        return (
          <Note
            id={id}
            layer={layer}
            onPointerDown={onLayerPointerDown}
            selectionColor={selectionColor}
          />
        );
      case LayerType.Summary:
        return (
          <Summary
            id={id}
            layer={layer}
            onPointerDown={onLayerPointerDown}
            selectionColor={selectionColor}
          />
        );
      default:
        console.warn("Unknown layer type at ");
        // <BlankObject 
        //   id={id}
        //   layer={layer}
        //   onPointerDown={onLayerPointerDown}
        //   selectionColor={selectionColor}
        // />

        return null;
    }
  }
);

export default LayerComponent;
