import { LiveObject } from "@liveblocks/client";
import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import { MAX_LAYERS } from "components/whiteboard/src/index";
import { CanvasMode } from "components/whiteboard/src/types";
import { generateUUID, penPointsToPathLayer } from "components/whiteboard/src/utils";
import { useWhiteboard } from "components/whiteboard/room/WhiteboardContext";

/**
   * Transform the drawing of the current user in a layer and reset the presence to delete the draft.
*/
export default function useInsertPath () {
    return useMutation(
        ({ storage, self, setMyPresence }, {setState = () => {}, lastUsedColor = "#000000"}) => {
          const liveLayers = storage.get("layers");
          const { pencilDraft } = self.presence;
          if (
            pencilDraft == null ||
            pencilDraft.length < 2 ||
            liveLayers.size >= MAX_LAYERS
          ) {
            setMyPresence({ pencilDraft: null });
            return;
          }
    
          const id = generateUUID();
          liveLayers.set(
            id,
            new LiveObject(penPointsToPathLayer(pencilDraft, lastUsedColor))
          );
    
          const liveLayerIds = storage.get("layerIds");
          liveLayerIds.push(id);
          setMyPresence({ pencilDraft: null });
          setState({ mode: CanvasMode.Pencil });
        },
        []
      );
}