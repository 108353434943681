import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import useAnalysis from "components/whiteboard/src/hooks/useAnalysis";
import useInsert from "components/whiteboard/src/hooks/useInsert";
import { LayerType } from "components/whiteboard/src/types";
import { toast } from "sonner";

/**
 * Select the layer if not already selected and start translating the selection
 * 
 * This function retrieves the selected objects from the storage and prepares them for copying to the clipboard.
 * It converts the selected objects into a string representation and writes it to the clipboard using the
 * navigator.clipboard.writeText() method.
 */
export default function useSummarize () {
    const { summarize } = useAnalysis();
    const insert = useInsert();

    return useMutation(async ({ self, storage }) => {
        const insertSummary = new Promise(async (resolve, reject) => {
            try{
                const selection = self.presence.selection;
        
                const selectedNotes = selection.filter(id => storage.get("layers").get(id)?.get("type") === LayerType.Note);
        
                const summary = await summarize(selectedNotes);
                
                // Call the insert function with the summary object
                insert({
                    object: {
                        type: LayerType.Summary,
                        x: 0,
                        y: 0,
                        width: 400,
                        height: 400,
                        title: summary.title,
                        text: summary.summary
                    }, selectAfterInsert: true, insertAtScreenCenter: true
                });
                resolve({})
            } catch (error) {
                reject(error);
            }
        });

        return toast.promise(insertSummary, {
            loading: "Summarizing",  
            success: (data) => {
                return toast("Your notes have been summarized",
                { 
                    description: `Created a new summary from ${self.presence.selection.length} notes`,
                    // action: <button onClick={() => >Insert</button>,
                })
            }, 
            error: "Error while summarizing"})
    },[]);
}