import { LiveObject } from "@liveblocks/client";
import { useHistory, useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import useInsert from "components/whiteboard/src/hooks/useInsert";
import useSummarize from "components/whiteboard/src/hooks/useSummarize";
import { LayerType, Point } from "components/whiteboard/src/types";
import useAnalysis from "components/whiteboard/src/hooks/useAnalysis";
import SimpleMasonry from 'simple-masonry-layout';

export default function useMoveIntoClusters () {
    const gutter = 48;
    const positionOffset: Point = { x: 500, y: 200 };
    const insert = useInsert();
    const { summarize } = useAnalysis();

    return useMutation(async ({ self, storage }, clusters: string[][]) => {
        const offset = {
            x: self.presence.camera.x - positionOffset.x,
            y: self.presence.camera.y - positionOffset.y
        };
        // Calculate total width needed for each cluster considering max 5 notes per row
        const masonryClusters = clusters.map(cluster => {
            const noteCount = Math.min(cluster.length, 5);
            const noteWidths = cluster.slice(0, noteCount).map(noteId => {
                const note = storage.get("layers").get(noteId) as LiveObject;
                return note ? note.get('width') : 0;
            });
            const width = noteWidths.reduce((a, b) => a + b, 0);

            const dimensions = cluster.map(noteId => {
                const note = storage.get("layers").get(noteId) as LiveObject;
                return {
                    width: note.get('width'),
                    height: note.get('height')
                };
            });

            return {
                settings: {
                    dimensions: dimensions,
                    columns: noteCount,
                    width: width + (gutter * noteCount),
                    gutter: gutter,
                },
                masonry: SimpleMasonry.generateRectangles({
                    dimensions: dimensions,
                    columns: noteCount,
                    width: width + (gutter * noteCount),
                    gutter: gutter,
                })
            };
        });

        // Add each cluster to storage with new positions and the total width
        masonryClusters.forEach(async (cluster, index) => {
            clusters[index].forEach((noteId, i) => {
                const layers = storage.get("layers");
                layers.get(noteId)?.set("x", cluster.masonry[i].x + offset.x); 
                layers.get(noteId)?.set("y", cluster.masonry[i].y + index * (250 + gutter) + offset.y); 
                layers.get(noteId)?.set("width", 250);
                layers.get(noteId)?.set("height", 250);
            });
            
            const summary = await summarize(clusters[index]);

            await insert({object: {
                    type: LayerType.Theme,
                    x: 0 + offset.x,
                    y: index * (250 + gutter) + offset.y,
                    width: cluster.masonry.length > 1 ? cluster.settings.width : 250,
                    height: 250,
                    theme: summary.title,
                },
                selectAfterInsert: false,
                insertAtScreenCenter: false,
            })
        })
    }, []
    );
}
