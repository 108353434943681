import { useState, useEffect } from 'react';
import { database } from 'services/Firebase';
import { useAuth } from 'hooks/AuthContext';
import { useWorkspace } from 'hooks/WorkspaceContext';
import 'firebase/firestore'; 
import { useToast } from 'hooks/ToastContext';

const useLayout = (layoutId) => {
    const { workspace } = useWorkspace();
    const { user } = useAuth();
    const [layout, setLayout] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!user || !workspace) return;
        setLoading(true);

        const unsubscribe = database.layouts.doc(layoutId)
            .onSnapshot((snapshot) => {
                const LayoutData = {
                    id: layoutId,
                    ...snapshot.data()
                };
                setLayout(LayoutData);
                setLoading(false);
            }, (error) => {
                console.error('Error fetching layout:', error);
                setLoading(false);
            });

        // Cleanup function
        return () => {
            unsubscribe(); // Unsubscribe from the snapshot listener when component unmounts
        };
    }, [user, workspace]);

    return { layout, loading };
};

export default useLayout;