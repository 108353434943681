import { useEffect, useState } from 'react';
import { database } from 'services/Firebase';
import { useAuth } from 'hooks/AuthContext';
import { useWorkspace } from 'hooks/WorkspaceContext';

const useFeedback = () => {
    const { user } = useAuth();
    const { workspace } = useWorkspace();
  
    const addFeedback = (feedback) => {
      return new Promise((resolve, reject) => {
        try {
          // Include userId in the response
          const responseWithUserId = { feedback: feedback, userId: user.uid, createdAt: database.getCurrentTimestamp(), workspaceId: workspace.id };
          database.feedback.add(responseWithUserId)
            .then(result => {
              resolve(result);
            })
            .catch(error => {
              console.error('Error adding response:', error);
              reject(error);
            });
        } catch (error) {
          console.error('Error adding response:', error);
          reject(error);
        }
      });
    };
  
    return {
      addFeedback,
    };
  };
  
  export default useFeedback;