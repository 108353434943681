import { useEffect, useRef, useState } from "react";
import { ThemeLayer } from "../../types.js";
import { text } from "stream/consumers";

type Props = {
  id: string;
  layer: ThemeLayer;
  onPointerDown: (e: React.PointerEvent, id: string) => void;
  selectionColor?: string;
};

export default function Theme(
  { layer, onPointerDown, id, selectionColor,  }: Props
) {
  const { x, y, width, height, theme } = layer;
  const [themeName, setThemeName] = useState(theme);

  const updateThemeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setThemeName(e.target.value);
  };

  return (
    <g>
      <foreignObject
        x={x}
        y={y - 12}
        width={width < 250 ? 250 : width}
        height={0}
        className="overflow-visible flex flex-row justify-end items-end border-2 border-red-500 rounded-md"
        >
        {/* Input Field */}
            <form className="absolute bottom-0 left-0 text-white text-wrap rounded-md text-start bg-black inline-block">
              <span role="textbox"
                contentEditable={true}
                suppressContentEditableWarning={true}
                className="select-none relative bottom-0 left-0 bg-transparent text-wrap text-sm font-medium text-white fill-white/25 outline-none px-1.5 py-[2px] inline-block"
                onChange={updateThemeName}
                autoFocus
              >
                {themeName || "New theme"}
              </span>
            </form>
    </foreignObject>

      {/* Rectangle */}
      <rect
        onPointerDown={(e) => onPointerDown(e, id)}
        style={{ transform: `translate(${x}px, ${y}px)` }}
        x={0}
        y={0}
        width={width < 250 ? 250 : width} // Ensure minimum width of 250px
        height={height < 250 ? 250 : height} // Ensure minimum height of 250px
        rx={12}
        ry={12}
        className="stroke-green-800 stroke-2 fill-transparent"
      />
    </g>
  );
}