import Logo from "components/logo/logo";
import { Button } from "components/ui/button.tsx";
import useInvite from "hooks/useInvite";
import { useParams } from "react-router-dom";

export default () => {
    const { joinWorkspace } = useInvite();
    const { inviteId } = useParams();
    // const [workspace, setWorkspace] = useState(null);

    // useEffect(() => {
    //     getWorkspace(workspaceId)
    //         .then((workspace) => {
    //             console.log(workspace);
    //             setWorkspace(workspace);
    //         })
    //         .catch(error => {
    //             console.error('Error getting workspace:', error);
    //             if (error.code === 'permission-denied') {
    //                 // navigate('/request-access/' + workspaceId)
    //             } else {
    //                 console.log(error);
    //             }
    //         });
    // }, []);

    return(
        <div className="mx-auto lg:w-[25vw] md:w-4/6 w-full px-5 py-auto h-full flex justify-center items-center">
            <div className="flex-col space-y-5 py-5 w-full">
                <div className="flex justify-center items-start flex-col space-y-3">
                    <Logo />
                    <h2>You're invited to join a workspace</h2>
                    <h4 className="text-slate-500">
                    </h4>
                </div>

                <Button className="w-full" onClick={(e) => joinWorkspace(inviteId)}>
                    Join workspace
                </Button>
            </div>
        </div>
    )
}