import { cn } from "lib/utils";
import { ThemeLayer, NoteLayer, CanvasMode } from "components/whiteboard/src/types";
import styles from "./Note.module.scss";
import { memo, useEffect, useRef, useState } from "react";
import { useHistory, useMutation } from "components/whiteboard/liveblocks.config";
import useResponse from "hooks/useResponse";
import { useWhiteboard } from "components/whiteboard/src/hooks/WhiteboardContext";
import { AutosizeTextAreaRef, AutosizeTextarea } from "components/ui/autosize-textarea";
import { Badge } from "components/ui/badge";

type Props = {
  id: string;
  layer: NoteLayer;
  onPointerDown: (e: React.PointerEvent, id: string) => void;
  selectionColor?: string;
};

function Note(
  { layer, onPointerDown, id, selectionColor }: Props
) {
  const { x, y, width, height, text, fill, source, metadata, originId } = layer;
  
  const { canvasState } = useWhiteboard();

  const { updateResponse } = useResponse();
  const [localText, setLocalText] = useState(text);
  const history = useHistory();

  const [moveSmoothly, setMoveSmoothly] = useState<boolean>(false);

  useEffect(() => {
    if(canvasState.mode === CanvasMode.Translating) {
      setMoveSmoothly(true);
    }
  }, [canvasState.mode]);

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setLocalText(e.target.value);
  };

  const blur = useMutation(({ storage, setMyPresence }) => {
    setMyPresence({ focused: false });
    const note = storage.get("layers").get(id);
    if (note) {
      note.set("text", localText);
      if(localText) updateResponse(id, { answer: localText });
      console.log("updateResponse", id, localText)
    }
  }, [localText]);

  const focus = useMutation(({ setMyPresence }) => {
    setMyPresence({ focused: true });
  }, []);

  return (
    <g  style={{ transform: `translate(${x}px, ${y}px)` }}     
        className={cn(!moveSmoothly && styles.note, "select-none")}
        onPointerDown={(e) => onPointerDown(e, id)}
    >
      <rect
        x={0}
        y={0}
        width={width ? (width < 250 ? 250 : width) : 250}
        height={height ? (height < 250 ? 250 : height) : 250}
        rx={12}
        ry={12}
        className={cn("fill-lime-200")}
      />
      {source && source !== "csv" && <SourceIcon source={source} />}
      
      <foreignObject className="text-sm leading-snug" x={24} y={source === "dovetail" ? 52 : 24} width={width - 48} height={height - (source === "dovetail" ? 52 : 24)}>
        <AutosizeTextarea variant="plain" placeholder={text} value={localText} onChange={handleChange} onFocus={focus} onBlur={blur}/>
      </foreignObject>

      <foreignObject x={16} y={height - 16 - 16} width={width - 32} height={16}>
        <div className="flex flex-row gap-1 items-end">
          {metadata && metadata.map((metadataItem, index) => (
            <Badge key={index} label={metadataItem.label} className={index % 2 === 0 ? "bg-green-600" : "bg-indigo-500"}>{metadataItem}</Badge>
          ))}
        </div>
      </foreignObject>
    </g>
  );
}

function SourceIcon({ source }: { source: string }) {
  switch (source) {
    case "dovetail":
      return (
        <svg x="24" y="24" width="24" height="24" viewBox="0 0 700 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1382_70441)">
            <path fillRule="evenodd" clipRule="evenodd" d="M700.001 500L525.002 600V400L350.004 298.875L525.002 200L700.001 300V500Z" fill="#190041"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M349.997 300L174.999 400V200L0 100L174.999 0L349.997 100V300Z" fill="#190041"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M349.997 700L174.999 800V600L0 500L174.999 400L349.997 500V700Z" fill="#190041"/>
          </g>
          <defs>
            <clipPath id="clip0_1382_70441">
              <rect width="700" height="800" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      );
    case "csv":
      return (
        <svg x="24" y="24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
      );
    default:
      return (
        <svg x="24" y="24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
      );
  }
}

export default memo(Note);