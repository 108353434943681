import logoName from "./logo_name.svg";
import logo from "./logo.svg";


export default ({showName = true, ...props}) => {
    return (
    <>
        { showName ?
            <img src={logoName} alt="Leapfrog Logo with name" {...props}/> :
            <img src={logo} alt="Leapfrog Logo" {...props}/>
        }
    </>
    )
}