import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Circle, CircleEllipsis, Combine, Filter, Frame, Import, Laugh, LayoutDashboard, ListChecks, MousePointer2, NotebookText, Pencil, PencilLine, RectangleHorizontal, Redo, ScanText, Square, StickyNote, TextCursorInput, Undo } from "lucide-react";
import { Button } from "components/ui/button";
import useLayouts from "hooks/useLayouts";
import { Separator } from "components/ui/separator";
import { useUndo, useRedo, useCanUndo, useCanRedo, useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import { CanvasMode, CanvasState, LayerType } from "../../types";
import useSummarize from "../../hooks/useSummarize";
import useGroupBySemantics from "../../hooks/useGroupBySemantics";
import { useWhiteboard } from "components/whiteboard/src/hooks/WhiteboardContext";
import useMoveIntoClusters from "components/whiteboard/src/hooks/useMoveIntoClusters";
import useCombine from "components/whiteboard/src/hooks/useCombine";
import { Theme } from "components/icons/theme";
import { Cluster } from "components/icons/cluster";
import Importer from "components/table/importer";
import useImport from "components/whiteboard/src/hooks/useImport";
import useTags from "components/whiteboard/src/hooks/useTags";
import { FilterButton } from "components/whiteboard/src/components/toolbar/filter-button";

type Props = {
  canvasState: CanvasState;
  setCanvasState: (newState: CanvasState) => void;
};

const Toolbar: React.FC<Props> = (
  { canvasState, setCanvasState }: Props
) => {
  const { layoutId } = useParams<{ layoutId: string }>();
  const { getLayout } = useLayouts();

  const [layout, setLayout] = useState<LayoutData | null>(null);

  const handleImport = useImport();

  const summarize = useSummarize();
  const combine = useCombine();
  const groupBySemantics = useGroupBySemantics();

  const undo = useUndo();
  const redo = useRedo();
  const canUndo = useCanUndo();
  const canRedo = useCanRedo();

  const selection = useSelf((me) => me.presence.selection);

  useEffect(() => {
    const fetchLayout = async () => {
      try {
        if (!layoutId) return;
        const layoutData = await getLayout(layoutId);
        // Do something with the layoutData, e.g., set it in state
        setLayout(layoutData);
      } catch (error) {
        console.error('Error fetching layout:', error);
      }
    };

    fetchLayout();
  }, [layoutId, getLayout]);

  // const split = async (numClusters: number) => {
  //   const clusters = await clusterByK(selection, numClusters);
  //   moveObjectsIntoClusters(clusters);
  // };

  return (
  <div className="relative left-4 top-[50vh] -translate-y-[50%] z-20 flex flex-col inline-flex space-y-2">
    <div className="inline-flex space-y-1.5 rounded-lg bg-white p-1 flex flex-col justify-between items-center shadow-sm border border-slate-200">
      <FilterButton/>
      <Tooltip>
        <TooltipTrigger>
            {/* Import */}
            <Importer 
              variant={"ghost"} 
              onImportDovetail={(data) => handleImport(data, 'dovetail')} 
              onImportCsv={(data) => handleImport(data, 'csv')}
              className="text-slate-800 size-[38px] p-[11px]" 
              >
                <Import className="size-4"/>
                <div className="sr-only">Import data</div>
            </Importer>
        </TooltipTrigger>
        <TooltipContent side="right">
          Import
        </TooltipContent>
      </Tooltip>
    </div>

    <div className="inline-flex space-y-1.5 rounded-lg bg-white p-1 flex flex-col justify-between items-center shadow-sm border border-slate-200">
        <Tooltip>
          <TooltipTrigger>
            <Button 
            size={"sm"} 
            variant={"ghost"} 
            className="text-slate-800 size-[38px] p-[11px]" 
            disabled={(
                canvasState.mode === CanvasMode.None ||
                canvasState.mode === CanvasMode.Translating ||
                canvasState.mode === CanvasMode.SelectionNet ||
                canvasState.mode === CanvasMode.Pressing ||
                canvasState.mode === CanvasMode.Resizing
              )}
              onClick={() => setCanvasState({ mode: CanvasMode.None })}>
              <MousePointer2 className="size-4"/>
              <div className="sr-only">
                Select
              </div>
            </Button>
          </TooltipTrigger>
          <TooltipContent side="right">
            Select
          </TooltipContent>
        </Tooltip>  
          
        <Tooltip>
          <TooltipTrigger>
            <Button
              size={"sm"} 
              variant={"ghost"} 
              className="text-slate-800 size-[38px] p-[11px]" 
              disabled={canvasState.mode === CanvasMode.Pencil}
              onClick={() => setCanvasState({ mode: CanvasMode.Pencil })}>
              <PencilLine className="size-4"/>
              <div className="sr-only">
                Draw
              </div>
            </Button>
          </TooltipTrigger>
          <TooltipContent side="right">
            Draw
          </TooltipContent>
        </Tooltip>


          {/* <Separator />

          <Button
            size={"sm"} 
            variant={"ghost"} 
            className="text-slate-800 size-[38px] p-[11px]" 
            disabled={
              canvasState.mode === CanvasMode.Inserting &&
              canvasState.layerType === LayerType.Rectangle
            }
            onClick={() =>
              setCanvasState({
                mode: CanvasMode.Inserting,
                layerType: LayerType.Rectangle,
              })
            }
            >
            <Square className="size-4"/>
            <div className="sr-only">
              Draw
            </div>
          </Button>
          <Button
            size={"sm"} 
            variant={"ghost"} 
            className="text-slate-800 size-[38px] p-[11px]" 
            disabled={
              canvasState.mode === CanvasMode.Inserting &&
              canvasState.layerType === LayerType.Ellipse
            }
            onClick={() =>
              setCanvasState({
                mode: CanvasMode.Inserting,
                layerType: LayerType.Ellipse,
              })
            }
            >
            <Circle className="size-4"/>
            <div className="sr-only">
              Draw
            </div>
          </Button> */}
          
          <Separator />

          
      <Tooltip>
        <TooltipTrigger>
          <Button size={"sm"} variant={"ghost"} className="text-slate-800 size-[38px] p-[11px]"
            onClick={() =>
              setCanvasState({
                mode: CanvasMode.Inserting,
                layerType: LayerType.Note,
              })
            }
            disabled={
              canvasState.mode === CanvasMode.Inserting &&
              canvasState.layerType === LayerType.Note
            }>
            <StickyNote className="size-4"/>
            <div className="sr-only">
              Create Note
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          Create Note
        </TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <Button size={"sm"} variant={"ghost"} className="text-slate-800 size-[38px] p-[11px]"
            onClick={() =>
              setCanvasState({
                mode: CanvasMode.Inserting,
                layerType: LayerType.Theme,
              })
            }
            disabled={
              canvasState.mode === CanvasMode.Inserting &&
              canvasState.layerType === LayerType.Theme
            }>
            <Theme className="size-4"/>
            <div className="sr-only">
              Create Theme
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          Create Theme
        </TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger>
          <Button size={"sm"} variant={"ghost"} className="text-slate-800 size-[38px] p-[11px]" onClick={() => combine()}>
            <Combine className="size-4"/>
            <div className="sr-only">
              Combine
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          Combine
        </TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger>
          <Button size={"sm"} variant={"ghost"} className="text-slate-800 size-[38px] p-[11px]" onClick={() => groupBySemantics()}>
            <Cluster className="size-4"/>
            <div className="sr-only">
              Group by Semantics
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          Group by Semantics
        </TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger>
          <Button size={"sm"} variant={"ghost"} className="text-slate-800 size-[38px] p-[11px]" onClick={() => summarize()} disabled={!selection || selection?.length === 0}>
            <ScanText className="size-4"/>
            <div className="sr-only">
              Summarize
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          Summarize
        </TooltipContent>
      </Tooltip>
    </div>
    <div className="inline-flex space-y-1.5 rounded-lg bg-white p-1 flex flex-col justify-between items-center shadow-sm border border-slate-200">
      <Tooltip>
        <TooltipTrigger>
          <Button size={"sm"} variant={"ghost"} className="text-slate-800 size-[38px] p-[11px]" onClick={undo} disabled={!canUndo}>
            <Undo className="size-4"/>
            <div className="sr-only">
              Undo
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          Undo
        </TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <Button size={"sm"} variant={"ghost"} className="text-slate-800 size-[38px] p-[11px]" onClick={redo} disabled={!canRedo}>
            <Redo className="size-4"/>
            <div className="sr-only">
              Redo
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          Redo
        </TooltipContent>
      </Tooltip>
    </div>
  </div>
  );
};

export default memo(Toolbar);