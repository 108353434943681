import { useSelf, useMutation } from "../../liveblocks.config";

/**
 * Delete all the selected layers.
 */
export default function useDeleteLayers() {
  return useMutation(
    ({ self, storage, setMyPresence }) => {
      if (self.presence.focused) return;
      const selection = self.presence.selection;

      const liveLayers = storage.get("layers");
      const liveLayerIds = storage.get("layerIds");
      for (const id of selection) {
        // Delete the layer from the layers LiveMap
        liveLayers.delete(id);
        // Find the layer index in the z-index list and remove it
        const index = liveLayerIds.indexOf(id);
        if (index !== -1) {
          liveLayerIds.delete(index);
        }
      }
      setMyPresence({ selection: [] }, { addToHistory: true });
    },
    []
  );
}
