export const freePlan = {
    "stripe_metadata_maxProjects": "3",
    "stripe_metadata_maxStorage": "3",
    "tax_code": "txcd_10103001",
    "images": [
        "/Images/Plan Images_Free plan.png"
    ],
    "metadata": {
        "maxStorage": "1",
        "maxProjects": "1",
        "maxTranscripts": "3",
        "transcription_hours": 3,
    },
    "prices": [
        {
            "id": "price_1MoqDACaBXHBWrAcPKRUIRqU",
            "type": "recurring",
            "unit_amount": 0,
            "tiers_mode": null,
            "trial_period_days": null,
            "interval_count": 1,
            "transform_quantity": null,
            "recurring": {
                "aggregate_usage": null,
                "interval_count": 1,
                "trial_period_days": null,
                "usage_type": "licensed",
                "interval": "month"
            },
            "billing_scheme": "per_unit",
            "tiers": null,
            "active": true,
            "interval": "month",
            "metadata": {},
            "tax_behavior": "unspecified",
            "currency": "usd",
            "product": "prod_Na0Dl8RWpyLSS1",
            "description": null
        },
        {
            "id": "price_1MoqDACaBXHBWrAcSbsTembD",
            "unit_amount": 0,
            "tiers_mode": null,
            "interval_count": 1,
            "recurring": {
                "usage_type": "licensed",
                "aggregate_usage": null,
                "interval_count": 1,
                "trial_period_days": null,
                "interval": "year"
            },
            "tax_behavior": "unspecified",
            "interval": "year",
            "trial_period_days": null,
            "tiers": null,
            "currency": "usd",
            "billing_scheme": "per_unit",
            "description": null,
            "metadata": {},
            "active": true,
            "type": "recurring",
            "transform_quantity": null,
            "product": "prod_Na0Dl8RWpyLSS1"
        }
    ],
    "active": true,
    "role": null,
    "name": "Free",
    "stripe_metadata_maxTranscripts": "10",
    "description": "For small projects with up to 3 interviews analyzed at once."
};
