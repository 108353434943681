import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "lib/utils"

const badgeVariants = cva(
  "inline-block w-fit items-center rounded-full px-1.5 py-0 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        neutral:
          "border-transparent bg-gray-100 text-slate-500 hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground border border-primary hover:bg-gray-100 text-primary",
        success: "bg-lime-600 hover:bg-lime-600/80 text-white",
        danger: "bg-red-600 hover:bg-red-600/80 text-white",
        dark: "bg-slate-900 hover:bg-dark/80 text-white",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className, 'whitespace-nowrap ')} {...props} />
  )
}

export { Badge, badgeVariants }
