import { CreateNewWhiteboard } from "pages/project/whiteboards"

export default () => {
    return(
        <div className="h-full flex flex-col justify-center items-center">
            <div className="rounded-lg p-6 border border-slate-200 flex flex-col space-y-3 max-w-[400px]">
                <h4 className="text-slate-800">
                    You don't have any whiteboards yet.
                </h4>
                <div className="text-slate-500 text-sm">
                    You can research your data on a whiteboard. This is where all your responses will be shown.
                </div>
                <CreateNewWhiteboard className="mt-4" variant="secondary" />
            </div>
        </div>
    )
}