import { useState, useCallback, Dispatch, SetStateAction } from "react";
import { Button } from "components/ui/button";
import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "components/ui/command";
import { ScrollArea } from "components/ui/scroll-area";
import { Check, Filter, Minus, Waves } from "lucide-react";
import useTags from "components/whiteboard/src/hooks/useTags";
import { cn } from "lib/utils";
import { useWhiteboard } from "components/whiteboard/src/hooks/WhiteboardContext";


export function FilterButton() {
    const { setDisableScreenInteractions } = useWhiteboard();
    const getTags = useTags();
    const [tags, setTags] = useState({});
    const selection = useSelf((me) => me.presence.selection);

    const onOpenChange = useCallback((open) => {
        if (open) {
            setTags(getTags());
        }
    }, [getTags]);

    const toggleTag = useMutation(({ self, setMyPresence }, tag) => {
        const selectedNotes = new Set(self.presence.selection);
        const tagNotes = tags[tag] || [];
    
        // Check if all notes belonging to the tag are selected
        const allNotesSelected = tagNotes.every(note => selectedNotes.has(note));
    
        // If all notes from the tag are selected, remove them all from selection
        if (allNotesSelected) {
            tagNotes.forEach(note => selectedNotes.delete(note));
        } else {
            // Otherwise, add all notes from the tag to the selection
            tagNotes.forEach(note => selectedNotes.add(note));
        }
    
        setMyPresence({ selection: Array.from(selectedNotes) });
    }, []);
    

    const getNotesCount = (tag) => tags[tag]?.length || 0;

    return (
        <Popover onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
                <div>
                    <Tooltip>
                        <TooltipTrigger>
                            <Button variant={"ghost"} className="text-slate-800 size-[38px] p-[11px]">
                                <Filter className="size-4" />
                                <div className="sr-only">Filter</div>
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right">Filter</TooltipContent>
                    </Tooltip>
                </div>
            </PopoverTrigger>
            <PopoverContent 
                className="w-48 p-0" align="start" side="right" alignOffset={-6} sideOffset={14}>
                <Command>
                    <CommandInput placeholder="Search tags..." />
                    <CommandEmpty>No tags found.</CommandEmpty>
                    <CommandGroup>
                        <ScrollArea className="h-96" onScroll={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                            }}>
                            {Object.keys(tags).map((tag) => (
                                <CommandItem
                                    key={tag}
                                    onSelect={() => toggleTag(tag)}
                                >
                                    <div className="mr-2 w-4">
                                        <Check
                                            className={cn(
                                                "h-4 w-4",
                                                // Check if all notes belonging to the tag are selected
                                                tags[tag]?.length === selection.filter(note => tags[tag]?.includes(note)).length ? "opacity-100" : "hidden"
                                            )}
                                        />

                                        <Minus
                                            className={cn(
                                                "h-4 w-4",
                                                // Check if at least one note belonging to the tag is selected
                                                selection.some(note => tags[tag]?.includes(note)) && tags[tag]?.length !== selection.filter(note => tags[tag]?.includes(note)).length ? "opacity-100" : "hidden"
                                            )}
                                        />
                                    </div>
                                    <span>{tag}</span>
                                    <span className="ml-auto mr-3 text-xs tracking-widest text-muted-foreground">{getNotesCount(tag)}</span>
                                </CommandItem>
                            ))}
                        </ScrollArea>
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    );
}