import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";
import { LiveObject } from "@liveblocks/client";
import { MAX_LAYERS } from "../index";
import { CanvasMode, Layer, Point } from "components/whiteboard/src/types";
import { generateUUID } from "components/whiteboard/src/utils";
import { useWhiteboard } from "components/whiteboard/src/hooks/WhiteboardContext";

/**
 * Insert multiple objects into the whiteboard
 * 
 * This function creates new objects to the whiteboard with a UID
 * We have two modes:
 * Insert one object at a time
 * Insert multiple objects at once
 * Creates a small offset to the x and y position to avoid overlapping
 */
export function useInsertMultiple() {
    const { setState } = useWhiteboard();

    const offset = {x: 25, y: 25};

    return useMutation(({ self, setMyPresence, storage }, {objects, selectAfterInsert = true, insertAtScreenCenter = true} : {objects: LiveObject<Layer>[], selectAfterInsert?: boolean, insertAtScreenCenter?: boolean, insertMultiple?: boolean}) => {
        try {
            const liveLayers = storage.get("layers");
            if (liveLayers.size >= MAX_LAYERS) {
                return;
            }

            // Generate a unique ID for the pasted object
            const pasteObjectIds = objects.map((object) => {
                    const id = object.toObject().id;
                    if(id) return id;
                });
            
            // Insert the pasted object at the current camera position (if insertAtScreenCenter is true)t
            insertAtScreenCenter && objects.forEach((object, index) => {
                object.set("x", self.presence.camera.x + offset.x * index - object.get("width") / 2);
                object.set("y", self.presence.camera.y + offset.y * index - object.get("height") / 2);
            });

            // Store the pasted objects in the storage
            objects.forEach((object, index) => {
                liveLayers.set(pasteObjectIds[index], objects[index]);
                storage.get('layerIds').push(pasteObjectIds[index]);
            });

            // Update the user's presence to select the pasted objects
            if(selectAfterInsert) setMyPresence({ selection: pasteObjectIds }, {addToHistory: true});
            setState({ mode: CanvasMode.None });
        } catch (error) {
            console.error('Failed to paste objects:', error);
        }
    }, []);
}