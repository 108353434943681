import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";

/**
 * Select the layer if not already selected and start translating the selection
 * 
 * This function retrieves the selected objects from the storage and prepares them for copying to the clipboard.
 * It converts the selected objects into a string representation and writes it to the clipboard using the
 * navigator.clipboard.writeText() method.
 */
export default function useCopy () {
    const selection = useSelf((me) => me.presence.selection);
    return useMutation(() => {
        // Write the string representation to the clipboard
        if(selection.length > 0) navigator.clipboard.writeText(selection.toString()).then(() => {
            // console.log('Objects copied to clipboard:', objectsString);
        })
        .catch(error => {
            // console.error('Failed to copy objects to clipboard:', error);
        });
        },
        [selection]
    );
}