import { cn } from "lib/utils.tsx";
import { ThemeLayer } from "../types.js";
import styles from "./Note.module.scss";
import { memo, useEffect, useMemo, useState } from "react";
import { useMutation } from "components/whiteboard/liveblocks.config.js";
import useResponse from "hooks/useResponse.js";
import { ScrollArea } from "components/ui/scroll-area.tsx";
import { ScanText } from "lucide-react";

type Props = {
  id: string;
  layer: ThemeLayer;
  onPointerDown: (e: React.PointerEvent, id: string) => void;
  selectionColor?: string;
};

function Summary(
  { layer, onPointerDown, id, selectionColor }: Props
) {
  const { x, y, width, height, fill, title, text } = layer;
  // console.log(title, text)
  return (
    <g style={{ transform: `translate(${x}px, ${y}px)` }}         
      onPointerDown={(e) => onPointerDown(e, id)}
    >
      <foreignObject x={0} y={0} width={width} height={height} className="shadow-mdoverflow-visible p-4 bg-white dark:bg-slate-800 rounded-md border border-slate-200 dark:border-slate-700">
        <div className="flex flex-row items-start justify-between">
          <p className={cn("text-black dark:text-white font-medium text-md leading-snug")} >{title}</p>
          <div className="p-2 bg-white border-slate-200 border dark:border-slate-700 dark:bg-slate-800 rounded-md inline-block">
            <ScanText className="size-4"/>
          </div>
        </div>
        <p className={cn("text-slate-900 dark:text-slate-100 text-base mt-1")}>
          {text}
        </p>
      </foreignObject>
    </g>
  );
}

export default memo(Summary);