import { useEffect, useState } from 'react';
import { database } from 'services/Firebase';
import { useAuth } from 'hooks/AuthContext';
import { useWorkspace } from 'hooks/WorkspaceContext';
const useResponse = () => {
    const { workspace } = useWorkspace();
    const { user } = useAuth();
    const [responses, setResponses] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if(!workspace || !user) return;

      const unsubscribe = database.responses.where('workspaceId', '==', workspace.id)
        .onSnapshot((snapshot) => {
          setLoading(true);
          const responseData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setResponses(responseData);
          setLoading(false);
        }, (error) => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });

      // Cleanup function
      return () => {
        unsubscribe(); // Unsubscribe from the snapshot listener when component unmounts
      };
    }, [user, workspace]);
  
    const addResponse = async (data = {}) => {
      try {
        // Include userId in the response
        const responseWithUserId = { ...data, userId: user.uid, createdAt: database.getCurrentTimestamp(), workspaceId: workspace.id };

        // Check if response has an ID, if not, let Firestore generate one
        let responseId;
        if (!data.id) {
          const responseDocRef = await database.responses.add(responseWithUserId);
          responseId = responseDocRef.id;
        } else {
          // Use the provided response ID
          responseId = data.id;
          // Add the response to the 'responses' collection with the provided ID
          database.responses.doc(data.id).set(responseWithUserId);
        }

        // Return the response object
        return  { id: responseId, ...responseWithUserId };

        // console.log('Response added successfully with ID:', responseId);
      } catch (error) {
        console.error('Error adding response:', error);
      }
    };

    const getResponse = async (id) => {
      try {
          const responseDoc = await database.responses.doc(id).get();
          if (responseDoc.exists) {
              return { id: responseDoc.id, ...responseDoc.data() };
          } else {
              console.log('No such response document!');
              return null;
          }
      } catch (error) {
          console.error('Error getting response:', error);
          return null;
      }
    };
  
    const updateResponse = async (id, updatedResponse) => {
      try {
        await database.responses.doc(id).update(updatedResponse);
        console.log('Response updated successfully', id, updatedResponse);
      } catch (error) {
        console.error('Error updating response:', error);
      }
    };
  
    const deleteResponse = async (id) => {
      try {
        await database.responses.doc(id).delete();
        console.log('Response deleted successfully');
      } catch (error) {
        console.error('Error deleting response:', error);
      }
    };
  
    return {
      responses,
      loading,
      addResponse,
      getResponse,
      updateResponse,
      deleteResponse
    };
  };
  
  export default useResponse;