import { useMutation, useSelf } from "components/whiteboard/liveblocks.config";

/**
   * Move all the selected layers to the back
*/
export default function useMoveToBack () {
    const selection = useSelf((me) => me.presence.selection);
    return useMutation(
        ({ storage }) => {
          const liveLayerIds = storage.get("layerIds");
          const indices: number[] = [];
    
          const arr = liveLayerIds.toArray();
    
          for (let i = 0; i < arr.length; i++) {
            if (selection.includes(arr[i])) {
              indices.push(i);
            }
          }
    
          for (let i = 0; i < indices.length; i++) {
            liveLayerIds.move(indices[i], i);
          }
        },
        [selection]
      );
}