import { useState, useEffect } from 'react';
import { useAuth } from 'hooks/AuthContext';
import { useWorkspace } from 'hooks/WorkspaceContext';
import 'firebase/firestore'; 
import { useToast } from 'hooks/ToastContext';
import { httpsCallable } from 'firebase/functions';
import { database, functions } from 'services/Firebase';

const useLayouts = () => {
    const { workspace } = useWorkspace();
    const { user } = useAuth();
    const { error } = useToast();
    const [layouts, setLayouts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!user || !workspace) return;
        setLoading(true);

        const unsubscribe = database.layouts.where('workspaceId', '==', workspace?.id)
            .onSnapshot((snapshot) => {
                const LayoutData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setLayouts(LayoutData);
                setLoading(false);
            }, (error) => {
                console.error('Error fetching layouts:', error);
                setLoading(false);
            });

        // Cleanup function
        return () => {
            unsubscribe(); // Unsubscribe from the snapshot listener when component unmounts
        };
    }, [user, workspace]);

    const addLayout = async (layoutData) => {
        if(!layoutData.name) return error('Please enter a name for the layout');
        
        // TODO: Add more layout types
        try {
            await database.layouts.add({ ...layoutData, type: 'whiteboard', createdAt: database.getCurrentTimestamp(), userId: user.uid, workspaceId: workspace.id });
            console.log('Layout added successfully');
        } catch (error) {
            // error('Error adding layout');
            console.error('Error adding layout: ', error);
        }
    };

    const duplicateLayout = async (layoutId) => {
        return new Promise((resolve, reject) => {
            var duplicate_layout = httpsCallable(functions, 'duplicate_layout');
            duplicate_layout({ layoutId: layoutId })
                .then((result) => {
                    console.log("result", result.data);
                    // Read result of the Cloud Function.
                    // success("Duplicated Layout");
                    resolve(result.data.text);
                }).catch((err) => {
                  console.error("error", err);
                  error("Error while duplicating");
                  reject(err.message);
              });
        });
    };

    const updateLayout = async (layoutId, updateData) => {
        try {
        await database.layouts.doc(layoutId).update(updateData);
        } catch (error) {
        console.error('Error updating layout: ', error);
        }
    };

    const deleteLayout = async (layoutId) => {
        try {
        await database.layouts.doc(layoutId).delete();
        } catch (error) {
        console.error('Error deleting layout: ', error);
        }
    };

    const getLayout = async (layoutId) => {
        try {
            const doc = await database.layouts.doc(layoutId).get();
            if (doc.exists) {
                return { id: doc.id, ...doc.data() };
            } else {
                console.log('No such layout found!');
                return null;
            }
        } catch (error) {
            console.error('Error getting layout: ', error);
        return null;
        }
    };

    return { loading, layouts, addLayout, duplicateLayout, updateLayout, deleteLayout, getLayout };
};

export default useLayouts;