import { useState, useEffect } from 'react';
import { database } from 'services/Firebase';
const useUsers = () => {
  const addUser = async (userData) => {
    try {
      await db.collection('users').add(userData);
    } catch (error) {
      console.error('Error adding user: ', error);
    }
  };

  const updateUser = async (userId, newData) => {
    try {
      await database.users.doc(userId).update(newData);
    } catch (error) {
      console.error('Error updating user: ', error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await database.users.doc(userId).delete();
    } catch (error) {
      console.error('Error deleting user: ', error);
    }
  };

  const getUser = async (userId) => {
    try {
      const userDoc = await database.users.doc(userId).get();
      if (userDoc.exists) {
        return { id: userDoc.id, ...userDoc.data() };
      } else {
        console.log('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error getting user: ', error);
      return null;
    }
  };

  return {addUser, updateUser, deleteUser, getUser };
};

export default useUsers;