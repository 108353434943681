import React, {memo, useMemo} from "react";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { useMutation, useOthers } from "components/whiteboard/liveblocks.config";
import { connectionIdToColor } from "../../utils";
import { cn } from "lib/utils"
import { User } from "@liveblocks/node";

const PresenceAvatar: React.FC<{ user: User<Presence, BaseUserMeta> }> = ({ user }) => {
  let presenceClass = '';
  let presenceText = '';

  // Determine presence styles and text based on the presence state
  switch (user?.presence.status) {
    case 'online':
      presenceClass = 'bg-green-500';
      presenceText = 'Online';
      break;
    case 'offline':
      presenceClass = 'bg-red-500';
      presenceText = 'Offline';
      break;
    case 'connecting':
      presenceClass = 'bg-yellow-500';
      presenceText = 'Connecting';
      break;
    default:
      presenceClass = 'bg-gray-500';
      presenceText = 'Unknown';
  }

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger>
          {user.presence.status && <div className={`h-1 w-1 z-10 relative top-2 right-0 rounded-full ${presenceClass} outline outline-2 outline-white`} />}
          <Avatar className={`size-6 outline outline-2 outline-slate-200`} style={{ outlineColor: connectionIdToColor(user.connectionId) }}>
            {user.info.src && <AvatarImage src={user.info.src} />}
            <AvatarFallback>{user.info.name?.charAt(0).toUpperCase() || user.info.email?.charAt(0).toUpperCase()}</AvatarFallback>
          </Avatar>
        </TooltipTrigger>
        {
          user.info.name || user.info.email &&
          <TooltipContent side="bottom" align="center" alignOffset={-6}>
            {user.info.name || user.info.email}
            {/* - {presenceText} */}
          </TooltipContent>
        }
      </Tooltip>
    </TooltipProvider>
  );
};

const AvatarStack: React.FC = () => {
  const users = useOthers();

  // Get the first three avatars and remaining count
  const firstThreeAvatars = users.slice(0, 3);
  const remainingCount = Math.max(0, users.length - 3);

  if(users.length > 0)
  return (
    <div className={cn("inline-flex top-4 right-4 z-10 space-x-3 px-3 py-2 flex flex-row justify-between items-center")}>
      {firstThreeAvatars.map((user, index) => (
          <PresenceAvatar key={index} user={user} />
      ))}
      {remainingCount > 0 &&
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger>
            <Avatar className={`size-6 mt-1`}>
              <AvatarFallback>                
                +{remainingCount}
            </AvatarFallback>
            </Avatar>
          </TooltipTrigger>
          <TooltipContent side="bottom" align="center" alignOffset={-6}>
            {remainingCount} more
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    }
    </div>
  );
}

export default AvatarStack;
