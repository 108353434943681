
import React, { memo, FormEvent, useEffect, useRef, useState } from "react";
import { Import, Settings } from "lucide-react";
import { Button } from "components/ui/button";
import useLayouts from "hooks/useLayouts.js";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import useLayout from "hooks/useLayout";
import { Skeleton } from "components/ui/skeleton";
import useDocumentTitle from "hooks/useDocumentTitle";
import Logo from "components/logo/logo";
import AvatarStack from "components/whiteboard/src/components/avatars/avatar-stack";
import { Share, ShareDialog } from "components/cards/share";

interface CanvasContextMenuProps {

}
  
export const EditorNav = ({} : CanvasContextMenuProps) => {
    const { layoutId } = useParams<{ layoutId: string }>();
    const { layout } = useLayout(layoutId);
    const { updateLayout } = useLayouts();

    useDocumentTitle(layout.name || "Leapfrog Whiteboard");

    const onRename = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const name = formData.get("name") as string;
        updateLayout(layoutId, { name: name });
    };

    const navigate = useNavigate();
    return ( 
      <div className="w-full absolute top-0 left-0 bg-white border-slate-200 border-b flex flex-row justify-between items-center px-4 py-1.5">
        <div className="flex flex-row items-center gap-x-1.5">
            <a role="button" onClick={() => navigate('/' + layoutId)} >
                <Logo showName={false} className="size-6"/>
            </a>
            <div className="text-base text-slate-900 font-semibold">
                {layout?.name || <Skeleton className="h-5 w-12"/>}
            </div>
        </div>

        <div className="flex flex-row items-center gap-x-1">
            <AvatarStack />

            <Dialog>
                <DialogTrigger>
                <Button variant={"default"} size="sm" className="">
                    Share
                </Button>
                </DialogTrigger>
                <ShareDialog />
            </Dialog>

            {/* Settings */}
            <Dialog>
                <DialogTrigger>
                <Button variant={"white"} size="sm">
                    <div className="sr-only">Settings</div>
                    <Settings className="w-4 h-4" />
                </Button>
                </DialogTrigger>
                <DialogContent>
                <form onSubmit={onRename}>
                    <DialogHeader>
                    <DialogTitle>Whiteboard settings</DialogTitle>
                    <DialogDescription>
                        You can rename your whiteboard here. This will change the name
                        for anyone in the workspace.
                        <div className="grid flex-1 gap-2 py-4">
                        <Label htmlFor="link" className="sr-only">
                            Name
                        </Label>
                        <Input
                            id="link"
                            defaultValue={layout?.name || ""}
                            name="name"
                            type="text"
                            placeholder="Enter a name"
                            className="h-9"
                        />
                        </div>
                    </DialogDescription>
                    </DialogHeader>

                    <DialogFooter className="flex flex-row justify-end w-full">
                    <DialogPrimitive.Close>
                        <Button size={"sm"} type="submit">
                        Rename
                        </Button>
                    </DialogPrimitive.Close>
                    </DialogFooter>
                </form>
                </DialogContent>
            </Dialog>

        </div>
      </div>
    )
  }