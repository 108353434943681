import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { PrivateRoutes } from './PrivateRoute';
import { PageNotFound } from 'pages/not-found';
import { AuthProvider } from './hooks/AuthContext';
import SignUp from "pages/sign-up";
import SignIn from "pages/sign-in";

import Sidebar from "components/navs/sidebar";
import { ToastProvider } from "hooks/ToastContext";
import Whiteboards from "pages/project/whiteboards";
import { Settings } from "pages/settings/settings";
import WorkspaceOverlay from "components/navs/workspace-settings-sidebar";
import { Share } from "components/cards/share";
import JoinWorkspace from "pages/access/join-workspace";
import Room from "components/whiteboard/src/index";
import { WorkspaceProvider } from "hooks/WorkspaceContext";
import NewWhiteboards from "pages/project/new-whiteboards";

function App() {
  return (
    <div className="h-screen w-screen">
      <ToastProvider >
        <AuthProvider>
            <Router>
              <Routes>
                  <Route path="/signup" element={<SignUp />}/>
                  <Route path="/signin" element={<SignIn />} />
                  <Route element={<PrivateRoutes/>}>
                      <Route path="/invite/:inviteId" element={<JoinWorkspace />} />

                      {/* TODO: Redirect on trying to enter workspace for everything below here 
                      and create request access page*/}
                      {/* <Route path="/request-access/:workspaceId" element={<RequestAccess />} /> */}
                      <Route element={<WorkspaceProvider><Outlet/></WorkspaceProvider>}>
                        <Route element={<Sidebar />}>
                          {/* <Route path="/workspace/:workspaceId" element={<Insights />}/> */}
                          <Route path="/" element={<Whiteboards />}/>  
                          <Route path="/:workspaceId" element={<Whiteboards />}/>  

                          <Route path="/:workspaceId/settings" element={<WorkspaceOverlay />}>
                            <Route path="/:workspaceId/settings" element={<Settings />}/>  
                            <Route path="/:workspaceId/settings/general" element={<Settings />}/>  
                            <Route path="/:workspaceId/settings/sharing" element={<Share />}/>  
                          </Route>  

                          {/* <Route path="/insights" element={<Insights />}/> */}
                          {/* <Route path="/responses/" element={<Responses/>}/> */}
                          <Route path="/:workspaceId/whiteboards/" element={<Whiteboards/>}/>
                          <Route path="/:workspaceId/newOverview/" element={<NewWhiteboards/>}/>

                          {/* <Route path=":workspaceId/whiteboard/deprecated/:layoutId" element={<Whiteboard/>}/> */}

                        </Route>
                        <Route path="/:workspaceId/whiteboard/:layoutId" element={<Room/>}/>
                      </Route>
                  </Route>
                  {/* 👇️ only match this when no other routes match */}
                  <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Router>
        </AuthProvider>
      </ToastProvider>
  </div>
  );
}

export default App;
