import { memo } from "react";
import { useOther, useSelf } from "../../../liveblocks.config";
import { connectionIdToColor } from "../../utils";
import { Camera } from "components/whiteboard/src/types";

type Props = {
  connectionId: number;
};

function Cursor({ connectionId }: Props) {
  //
  // RATIONALE:
  // Each cursor itself subscribes to _just_ the change for the user. This
  // means that if only one user's cursor is moving, only one <Cursor />
  // component has to re-render. All the others can remain idle.
  //
  const cursor = useOther(connectionId, (user) => user.presence.cursor);
  const camera: Camera = useSelf((me) => me.presence.camera);
  const otherCamera = useOther(connectionId, (user) => user.presence.camera);
  const name = useOther(connectionId, (user) => user.info?.name || user.info?.email || "Anonymous");

  if (!cursor) {
    return null;
  }

  const {x, y} = cursor;

  return (
    <g 
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        transform: `translateX(${x}px) translateY(${y}px) scale(${1/camera.zoom})`,
        transition: "transform 0.1s ease-in-out", // Transition for translate transformation
        transformOrigin: "top left", // Set the transform origin
      }}>
      <path
        d="M0.258907 1.38113C0.0545177 0.517248 0.993288 -0.164806 1.75173 0.296534L24.7907 14.3106C25.4521 14.7129 25.4252 15.6818 24.7425 16.0468L15.7194 20.8706C15.6384 20.9139 15.5638 20.9681 15.4977 21.0317L8.13383 28.1133C7.57578 28.65 6.6458 28.3762 6.46754 27.6228L0.258907 1.38113Z"
        fill={connectionIdToColor(connectionId)}
      />
        <foreignObject x="20" y="20" width="1" height="1" className="overflow-visible">
          <div className="text-xs font-semibold inline-block text-white px-1.5 py-[2px] rounded-full text-center ml-4"
            style={{
                backgroundColor: connectionIdToColor(connectionId),
            }}>
          {name.toString()}
          </div>
        </foreignObject>
    </g>
  );
}

export default memo(Cursor);
