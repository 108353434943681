"use client"

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "components/ui/avatar.tsx"
import { Button } from "components/ui/button.tsx"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card.tsx"
import { DialogContent, DialogDescription, DialogHeader } from "components/ui/dialog"
import { Input } from "components/ui/input.tsx"
import { Label } from "components/ui/label.tsx"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select.tsx"
import { Separator } from "components/ui/separator.tsx"
import { useToast } from "hooks/ToastContext"
import { useWorkspace } from "hooks/WorkspaceContext"
import useUsers from "hooks/useUsers"
import { useEffect, useState } from "react"
import { toast } from "sonner"

export function Share() {
    const { notify } = useToast();
    const { workspace, inviteToWorkspace, removeFromWorkspace } = useWorkspace();

    const [link, setLink] = useState("");
    const [invitee, setInvitee] = useState("");

    const copyLink = (e) => {
        e.preventDefault();
        
        // Copying link to clipboard
        navigator.clipboard.writeText(link)
            .then(() => {
                notify("Link copied to clipboard");
            })
            .catch((error) => {
                console.error('Error copying link to clipboard: ', error);
            });
    }

    const send_invite = (e) => {
        e.preventDefault();
        if(!invitee) return;
        const invite = inviteToWorkspace(invitee)
        toast.promise(invite, {loading: "Inviting " + invitee, success: "Invited " + invitee, error: (error) => `Error inviting ${invitee}: ${error.message || error}`})
        setInvitee("")
    }

    return (
    <Card className="xl:w-1/2 w-full">
        <CardHeader className="pb-3">
            <CardTitle>Share workspace</CardTitle>
            <CardDescription>
                Anyone with the link can view this workspace.
            </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
            {/* <div className="flex space-x-2">
            <Label htmlFor="link" className="sr-only">
                Link
            </Label>
            <Input
                id="link"
                value={link}
                readOnly
            />
            <Button variant="secondary" className="shrink-0" onClick={copyLink}>
                Copy Link
            </Button>
            </div> */}
            {/* <Separator className="my-4" /> */}
            <form className="flex flex-col space-y-2" onSubmit={(e) => send_invite(e)}>
                <Label htmlFor="link">
                    Invite people
                </Label>
                <div className="flex flex-row space-x-2">
                    <Input
                        id="link"
                        placeholder="example@acme.com"
                        onChange={(e) => setInvitee(e.target.value)}
                        type="email"
                        value={invitee}
                    />
                    <Button role="submit" variant="secondary" className="shrink-0">
                        Invite
                    </Button>
                </div>
                <div className="text-muted-foreground text-xs">
                    Invites are valid for 7 days after being sent.
                </div>
            </form>

            <div className="space-y-4">
            <h4 className="text-sm font-medium">People with access</h4>
            <div className="grid gap-6">
                {workspace && Object.entries(workspace.roles)
                    // Show first owner, then editor, then viewer
                    .sort(([userId1, role1], [userId2, role2]) => {
                        // Define numerical values for each role type
                        const roleOrder = { owner: 0, editor: 1, viewer: 2 };
                        
                        // Compare roles based on their numerical values
                        const roleComparison = roleOrder[role1] - roleOrder[role2];
                        
                        // If roles are the same, sort by user ID
                        return roleComparison !== 0 ? roleComparison : userId1.localeCompare(userId2);
                    })
                    .map(([userId, role]) => (
                        <AccessAvatar key={userId} userId={userId} role={role} onRemove={() => removeFromWorkspace(userId)}/>
                    ))
                }
            </div>
            </div>
        </CardContent>
    </Card>
    )
}

export function ShareDialog() {
    const { notify } = useToast();
    const { workspace, inviteToWorkspace, removeFromWorkspace } = useWorkspace();

    const [link, setLink] = useState("");
    const [invitee, setInvitee] = useState("");

    const copyLink = (e) => {
        e.preventDefault();
        
        // Copying link to clipboard
        navigator.clipboard.writeText(link)
            .then(() => {
                notify("Link copied to clipboard");
            })
            .catch((error) => {
                console.error('Error copying link to clipboard: ', error);
            });
    }

    const send_invite = (e) => {
        e.preventDefault();
        if(!invitee) return;
        const invite = inviteToWorkspace(invitee)
        toast.promise(invite, {loading: "Inviting " + invitee, success: "Invited " + invitee, error: (error) => `Error inviting ${invitee}: ${error.message || error}`})
        setInvitee("")
    }

    return (
    <DialogContent className="xl:w-1/2 w-full">
        <DialogHeader className="pb-3">
            <CardTitle>Share workspace</CardTitle>
            <CardDescription>
                Giving access will give access to the whole workspace.
            </CardDescription>
        </DialogHeader>
        <div className="space-y-4">
            {/* <Separator className="my-4" /> */}
            <form className="flex flex-col space-y-2" onSubmit={(e) => send_invite(e)}>
                <Label htmlFor="link">
                    Invite people
                </Label>
                <div className="flex flex-row space-x-2">
                    <Input
                        id="link"
                        placeholder="example@acme.com"
                        onChange={(e) => setInvitee(e.target.value)}
                        type="email"
                        value={invitee}
                    />
                    <Button role="submit" variant="secondary" className="shrink-0">
                        Invite
                    </Button>
                </div>
                <div className="text-muted-foreground text-xs">
                    Invites are valid for 7 days after being sent.
                </div>
            </form>

            <div className="space-y-4">
            <h4 className="text-sm font-medium">People with access</h4>
            <div className="grid gap-6">
                {workspace && Object.entries(workspace.roles)
                    // Show first owner, then editor, then viewer
                    .sort(([userId1, role1], [userId2, role2]) => {
                        // Define numerical values for each role type
                        const roleOrder = { owner: 0, editor: 1, viewer: 2 };
                        
                        // Compare roles based on their numerical values
                        const roleComparison = roleOrder[role1] - roleOrder[role2];
                        
                        // If roles are the same, sort by user ID
                        return roleComparison !== 0 ? roleComparison : userId1.localeCompare(userId2);
                    })
                    .map(([userId, role]) => (
                        <AccessAvatar key={userId} userId={userId} role={role} onRemove={() => removeFromWorkspace(userId)}/>
                    ))
                }
            </div>
            </div>
        </div>
    </DialogContent>
    )
}

const AccessAvatar = ({ userId, role, onRemove }) => {
    const { getUser } = useUsers();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await getUser(userId);
            setUser(user);
        };

        fetchUser();
    }, [userId, getUser]);

    let selectValue = "edit"; // Default value

    if (role === "owner") {
        selectValue = "edit"; // You can set the owner's default role here
    } else if (role === "editor") {
        selectValue = "edit"; // You can set the editor's default role here
    } else if (role === "viewer") {
        selectValue = "view"; // You can set the viewer's default role here
    }

    if(!user) return;

    return (
        <div className="flex items-center justify-between space-x-4">
            <div className="flex items-center space-x-4">
                <Avatar>
                    <AvatarImage src="/avatars/03.png" alt="Image" />
                    <AvatarFallback>{user?.name?.charAt(0) || user?.email?.charAt(0) || ""}</AvatarFallback>
                </Avatar>
                <div>
                    <p className="text-sm font-medium leading-none">
                        {user?.name || user?.email || ""}
                    </p>
                    <p className="text-sm text-muted-foreground">{role}</p>
                </div>
            </div>
            {/* <Select defaultValue={selectValue} 
            disabled={selectValue === "owner"}
            >
                <SelectTrigger className="ml-auto w-[110px]" aria-label="Edit">
                    <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="edit">Can Edit</SelectItem>
                    <SelectItem value="view">Can View</SelectItem>
                </SelectContent>
            </Select> */}
            { role !== "owner" && <Button variant="secondary" className="shrink-0" disabled={role === "owner"} onClick={() => onRemove()}>Remove</Button> }
        </div>
    );
};
