import * as DialogPrimitive from "@radix-ui/react-dialog";
import React, { useState } from "react";
import Papa from "papaparse";
import { MultiSelect } from "components/ui/multi-select";
import { Button, ButtonProps } from "components/ui/button";
import { Input } from "components/ui/input";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "components/ui/dialog";
import useDataImporter from "hooks/useDataImporter";
import { Separator } from "components/ui/separator";
import { Badge } from "components/ui/badge";
import { toast } from "sonner";

const speakerOptions = [
    { value: 0, label: "Speaker 1" },
    { value: 1, label: "Speaker 2" },
    { value: 2, label: "Speaker 3" },
    { value: 3, label: "Speaker 4" }
];

interface ImporterProps {
    onImportDovetail: (data: any) => void;
    onImportCsv: (data: any) => void;
    props?: any;
    children?: React.ReactNode;
}

export default ({ onImportDovetail = () => {}, onImportCsv = () => {}, ...props }: ImporterProps) => {
    return (
        <Dialog>
            <DialogTrigger>
                <Button variant={"white"} size={"sm"} {...props}>{props.children}</Button>
            </DialogTrigger>
            <Content onImportDovetail={(data) => onImportDovetail(data)} onImportCsv={(data) => onImportCsv(data)}/>
        </Dialog>
    );
};

interface ContentProps {
    onImportDovetail: (data: any) => void;
    onImportCsv: (data: any) => void;
}

const Content = ({ onImportDovetail = () => {}, onImportCsv = () => {} }: ContentProps) => {
    const [file, setFile] = useState(null);
    const [step, setStep] = useState(0);
    const { handleDovetailImport, handleImportCsv } = useDataImporter();
    const [speakers, setSpeakers] = useState([]);

    const dovetailImport = async () => {
        if (!file) return;
        const responses = await handleDovetailImport(file, speakers);
        onImportDovetail(responses);
        setFile(null);
    };

    const importCsv = async () => {
        if (!file) return;
        const importCsvPromise = handleImportCsv(file).then(responses => {
            onImportCsv(responses);
            setFile(null);
        });
        toast.promise(importCsvPromise, {
            loading: "Importing CSV Data",
            success: "CSV Data Imported Successfully",
            error: "Error Importing CSV Data"
        });
    };

    const handleFileChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0] || null;
        setFile(file);

        if (file && file.name.endsWith('.jsonl')) {
            setStep(1);
        }
    };
    
    switch(step) {
    case 0: 
        return (
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Import data</DialogTitle>
                </DialogHeader>
                <DialogDescription className="mt-0 space-y-3">
                    <Separator />
                    <div className="w-full flex flex-row justify-between gap-3 items-center">
                        <div className="flex flex-row gap-3 items-center">
                            <div className="h-full">
                                <svg width="36" height="36" viewBox="0 0 700 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1382_70441)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M700.001 500L525.002 600V400L350.004 298.875L525.002 200L700.001 300V500Z" fill="#190041"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M349.997 300L174.999 400V200L0 100L174.999 0L349.997 100V300Z" fill="#190041"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M349.997 700L174.999 800V600L0 500L174.999 400L349.997 500V700Z" fill="#190041"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_1382_70441">
                                    <rect width="700" height="800" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="flex flex-col">
                                <div className="font-bold text-slate-900 dark:text-slate-200">
                                    Dovetail
                                </div>
                                <div className="text-slate-500 dark:text-slate-400">
                                    Import data from Dovetail. Please export your project and upload Notes.jsonl. 
                                </div>
                            </div>
                        </div>
                        <input
                            type="file"
                            id="fileInput"
                            accept=".jsonl"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileChange(e)}
                            />
                            <Button
                            variant={"default"}
                            size={"sm"}
                            onClick={() => { document.getElementById('fileInput')?.click(); }}
                            >
                            Import
                            </Button>                                
                        </div>
                    <Separator />
                    <div>
                        <div className="font-bold text-slate-900 dark:text-slate-200">Upload .csv file</div>
                        <div>
                            Please make sure your file has the following required column names:
                        <br/>
                            <Badge variant="outline">note</Badge> <Badge variant="outline">tags</Badge>
                        <br/>

                        <br/>
                            You can download an example of .csv file&nbsp;
                            <a className="inline-block text-sm font-base" href={process.env.PUBLIC_URL + "/example-import.csv"}>here</a>
                        </div>
                        <Input className="my-2" type="file" accept=".csv" onChange={handleFileChange} />
                        <div className="text-xs">
                            Your .csv will not be saved to the database
                        </div>
                    </div>
                </DialogDescription>
                <DialogFooter className="flex flex-row justify-between w-full">
                    <DialogPrimitive.Close>
                        <Button 
                        variant={"link"} 
                        size={"sm"}
                        onClick={() => {}}>Cancel</Button>
                    </DialogPrimitive.Close>
                    <DialogPrimitive.Close>
                        <Button onClick={importCsv} 
                        className="ml-auto" 
                        size={"sm"}
                        variant={"default"}
                        disabled={!file}
                        >Import</Button>
                    </DialogPrimitive.Close>
                </DialogFooter>
            </DialogContent>
        )
    case 1:
        return (
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Import data</DialogTitle>
                </DialogHeader>
                <DialogDescription className="mt-0 space-y-3">
                    <Separator />
                    <div className="w-full flex flex-col justify-between gap-3">
                        <div className="flex flex-col">
                            <div className="font-bold text-slate-900 dark:text-slate-200">
                                Choose speakers
                            </div>
                            <div className="text-slate-500 dark:text-slate-400">
                                Please indicate which speakers are the participants
                            </div>
                        </div>
                        <MultiSelect options={speakerOptions} onChange={(speakers) => setSpeakers(speakers)} />
                    </div>
                </DialogDescription>
                <DialogFooter className="flex flex-row justify-between w-full">
                    <DialogPrimitive.Close>
                        <Button 
                        variant={"link"} 
                        size={"sm"}
                        onClick={() => {setFile(null); setStep(0)}}>Cancel</Button>
                    </DialogPrimitive.Close>
                    <DialogPrimitive.Close>
                        <Button onClick={dovetailImport}
                        className="ml-auto" 
                        size={"sm"}
                        variant={"default"}
                        disabled={speakers.length === 0}
                        >Import</Button>
                    </DialogPrimitive.Close>
                </DialogFooter>
            </DialogContent>
        )
    }
}