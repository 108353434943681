import * as React from "react";
import { useEffect } from "react";

import { cn } from "lib/utils.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectSeparator,
  SelectButton,
} from "components/ui/select.tsx";
import { useWorkspace } from "hooks/WorkspaceContext";
import { Avatar, AvatarFallback } from "components/ui/avatar.tsx";
import { Skeleton } from "components/ui/skeleton.tsx";
import { Separator } from "components/ui/separator.tsx";
import { PlusCircle, Settings2 } from "lucide-react";

import { useNavigate } from "react-router-dom";
import WorkspaceOverlay from "components/navs/workspace-settings-sidebar";
import { Timestamp } from "@google-cloud/firestore";

interface WorkspaceSwitcherProps {
  isCollapsed: boolean;
}

interface Workspace {
  createdAt: Timestamp;
  createdBy: string;
  id: string;
  name: string;
  roles: { [key: string]: string };
  type: "personal" | "shared";
}

export function WorkspaceSwitcher({ isCollapsed }: WorkspaceSwitcherProps) {
  const { workspaces, workspace, createWorkspace } = useWorkspace();
  const navigate = useNavigate();


  const handleWorkspaceChange = (newWorkspace: Workspace) => {
    // Update the cookie when the workspace is changed
    navigate(`/${newWorkspace.id}/`)
    const selectedWorkspaceDetails = workspaces.find(
      (space) => space.id === newWorkspace
    );
    if (selectedWorkspaceDetails) {
    }
  };

  const personalWorkspaces = workspaces.filter(
    (workspace) => workspace.type === "personal"
  );
  const teamWorkspaces = workspaces.filter(
    (workspace) => workspace.type !== "personal"
  );

  if (workspaces.length > 0 && workspace)
    return (
      <Select defaultValue={workspace} onValueChange={handleWorkspaceChange}>
        <SelectTrigger
          className={cn(
            "flex items-center gap-2 justify-start [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0",
            isCollapsed &&
              "flex h-9 w-9 shrink-0 items-center px-1.5 [&>span]:w-auto [&>svg]:hidden bg-green-50"
          )}
          aria-label="Select workspace"
        >
          <SelectValue
            placeholder={
              <SelectedWorkspace
                workspace={workspaces.find((space) => space.id === workspace.id)}
                isCollapsed={isCollapsed}
              />
            }
          >
            <SelectedWorkspace
              workspace={workspaces.find((space) => space.id === workspace.id)}
              isCollapsed={isCollapsed}
            />
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="p-0 overflow-x-auto max-h-[400px]">
          <div className="p-[5px]">
            {personalWorkspaces.length > 0 && (
              <>
                <div className="px-2 py-1.5 font-semibold text-slate-500 subtle">
                  Personal
                </div>
                {personalWorkspaces.map((space) => (
                  <SelectItem key={space.id} value={space} className="pl-0 space-x-0">
                    <div className="flex items-center justify-start gap-2 text-foreground">
                      {space && <WorkspaceAvatar workspace={space} />}
                      {space.name}
                    </div>
                  </SelectItem>
                ))}
              </>
            )}
            {teamWorkspaces.length > 0 && (
              <>
                <div className="px-2 py-1.5 font-semibold text-slate-500 subtle">
                  Teams
                </div>
                {teamWorkspaces.map((space) => (
                  <SelectItem key={space.id} value={space} className=" pl-0 space-x-0">
                    <div className="flex items-center justify-start gap-2 text-foreground">
                      {space && <WorkspaceAvatar workspace={space} />}
                      {space.name}
                    </div>
                  </SelectItem>
                ))}
              </>
            )}
          </div>

          <SelectSeparator className="bg-slate-100"/>
          <div className="px-[5px] flex flex-col">
            <SelectButton onClick={() => navigate(`/${workspace.id}/settings/`)}>
              <Settings2 className="h-5 w-5"/><span>Workspace settings</span>
            </SelectButton>
          </div>
          <div className="px-[5px] flex flex-col">
            <SelectButton onClick={() => createWorkspace("Team workspace")}>
              <PlusCircle className="h-5 w-5"/><span>Create team</span>
            </SelectButton>
          </div>
        </SelectContent>
      </Select>
    );
}

const SelectedWorkspace = ({
  workspace,
  isCollapsed,
}: {
  workspace: { name: string; photoUrl: string };
  isCollapsed: boolean;
}) => {
  if (!workspace)
    return (
      <div className="flex items-center space-x-4">
        <Skeleton className="h-5 w-5 rounded-full" />
        {!isCollapsed && (
          <div className="space-y-2">
            <Skeleton className="h-3 w-[100px]" />
          </div>
        )}
      </div>
    );

  return (
    <>
      <WorkspaceAvatar workspace={workspace} />
      {!isCollapsed && (
        <span className={cn("ml-2", isCollapsed && "hidden")}>
          {workspace.name}
        </span>
      )}
    </>
  );
};

const WorkspaceAvatar = ({
  workspace,
}: {
  workspace: { name: string; photoUrl: string };
}) => {
  return (
    <>
      {workspace.photoUrl ? (
        <img src={workspace.photoUrl} alt={workspace.name} />
      ) : (
        <Avatar className="h-5 w-5 shrink-0 text-foreground mx-0">
          <AvatarFallback className="bg-green-50">
            {workspace.name.charAt(0).toUpperCase() || ""}
          </AvatarFallback>
        </Avatar>
      )}
    </>
  );
};