import { memo, useEffect } from "react";
import { Camera, Color, LayerType } from "../types";
import useDeleteLayers from "../hooks/useDeleteLayers";
import useSelectionBounds from "../hooks/useSelectionBounds";
import { useSelf, useMutation, useStorage } from "../../liveblocks.config";
import useMoveToBack from "components/whiteboard/src/hooks/useMoveToBack";
import useMoveToFront from "components/whiteboard/src/hooks/useMoveToFront";
import { Button } from "components/ui/button";
import { ViewSource } from "components/icons/view-source";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { BringToFront, SendToBack, Trash2 } from "lucide-react";
import { cn } from "lib/utils";
import useGoToObject from "components/whiteboard/src/hooks/useGoToObject";
import { Separator } from "components/ui/separator";

type SelectionToolsProps = {
  isAnimated: boolean;
  camera: Camera;
  setLastUsedColor: (color: Color) => void;
};

function SelectionTools(
  { isAnimated, camera, setLastUsedColor }: SelectionToolsProps
) {
  const selection = useSelf((me) => me.presence.selection);

  const moveToFront = useMoveToFront();
  const moveToBack = useMoveToBack();
  const deleteLayers = useDeleteLayers();

  /**
   * Change the color of all the selected layers
   */
  const setFill = useMutation(
    ({ storage }, fill: Color) => {
      const liveLayers = storage.get("layers");
      setLastUsedColor(fill);
      selection.forEach((id) => {
        liveLayers.get(id)?.set("fill", fill);
      });
    },
    [selection, setLastUsedColor]
  );


  const selectionBounds = useSelectionBounds();
  if (!selectionBounds) {
    return null;
  }

  const x = (selectionBounds.width / 2 + selectionBounds.x -camera.x ) * camera.zoom  + window.innerWidth/2;
  const y = (selectionBounds.y - camera.y  ) * camera.zoom  + window.innerHeight/2;

  return (
    <div
      className={"bg-white border border-slate-200 rounded-lg p-1.5 gap-1 absolute flex flex-row select-none divide-x divide-slate-200"}
      style={{
        transform: `translate(calc(${x}px - 50%), calc(${y - 16}px - 100%))`,
      }}
    >
      <div className="flex flex-row gap-1">
        {/* <ColorPicker onChange={setFill} /> */}
        <Tooltip>
          <TooltipTrigger>
            <Button variant="ghost" onClick={moveToFront}               
            className="text-slate-800 size-[38px] p-[11px]" 
            >
              <BringToFront/>
              <div className="sr-only">Bring to front</div>
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top">
            Bring to front
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger>
            <Button variant="ghost" onClick={moveToBack}               
            className="text-slate-800 size-[38px] p-[11px]" 
            >
              <SendToBack/>
              <div className="sr-only">Send to back</div>
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom">
            Send to back
          </TooltipContent>
        </Tooltip>
      </div>
      <GoToSourceNote layerId={selection[0]} />
      <Tooltip>
        <TooltipTrigger>
          <Button variant="ghost" onClick={deleteLayers}               
          className="text-slate-800 size-[38px] p-[11px]" 
          >
            <Trash2/>
            <div className="sr-only">Delete</div>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="bottom">
          Delete
        </TooltipContent>
      </Tooltip>
    </div>
  );
}

const GoToSourceNote = (selection: { layerId: string; }) => {
  const layer = useStorage((root) => root.layers.get(selection.layerId));
  const goToObject = useGoToObject();

  if(layer?.type !== LayerType.Note) return;
  if(!layer.originId) return;
  return(
    <Tooltip>
      <TooltipTrigger>
      <Button variant="ghost" onClick={() => goToObject({
        layerId: layer.originId,
        setSelection: false,
      })}               
      className="text-slate-800 size-[38px] p-[11px]" 
      >
          <ViewSource/>
          <div className="sr-only">Go to source</div>
        </Button>
      </TooltipTrigger>
      <TooltipContent side="bottom">
        Go to source
      </TooltipContent>
    </Tooltip>
  )
}

export default SelectionTools;
